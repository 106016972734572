const downloadAsCsv = async (transcriptJobs: any) => {
  const headerRow = ["Job ID", "Status", "Priority", "Assigned To", "Created On", "Audio Length", "User ID", "First Name", "Last Name", "Transcribe Start", "Transcription Sent"];
  const csvRows = [headerRow, ...transcriptJobs.map(makeCsvRow)];
  const csvContent = csvRows.map(e => e.join(",")).join("\n");
  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
  const url = URL.createObjectURL(blob);

  const link = document.createElement("a");
  link.href = url;
  link.download = `${getFormattedDate()}_job_list.csv`;
  link.click();
}

const getFormattedDate = () => {
  const date = new Date();
  const year = date.getFullYear();
  const month = ("0" + (date.getMonth() + 1)).slice(-2);
  const day = ("0" + date.getDate()).slice(-2);
  return "" + year + month + day;
}

const makeCsvRow = (transcriptJob: any): string[] => {
  return [
    String(transcriptJob.id),
    transcriptJob.status,
    transcriptJob.priority,
    transcriptJob.assignedEmployee ? `${transcriptJob?.assignedEmployee?.firstName} ${transcriptJob?.assignedEmployee?.lastName}` : 'Unassigned',
    transcriptJob.createdOn,
    transcriptJob.audioLengthInSeconds,
    transcriptJob.customerUserId,
    transcriptJob.firstName,
    transcriptJob.lastName,
    transcriptJob.transcriptionStart,
    transcriptJob.transcriptionEnd
  ]
}

export {
  downloadAsCsv
}
import { useModal } from "src/shared/context/ModalProvider";
import Modal from "../Modal";
import { TranscriptJob, updateTranscriptJob, Employee, listEmployees } from "src/shared/api";
import { useState, useEffect } from "react";
import { toast } from "sonner";
import { AssigneeBadge } from "../AssigneeBadge";
import { Box, Select } from "@chakra-ui/react";
import LoadingSpinner from "../LoadingSpinner";

interface ModalUpdateJobAssigneeProps {
  transcriptJob: TranscriptJob;
  onSave?: () => Promise<void> | void;
}

export const ModalUpdateJobAssignee = ({
  transcriptJob,
  onSave,
}: ModalUpdateJobAssigneeProps) => {
  const { closeModal } = useModal();
  const [loading, setLoading] = useState(false);
  const [employees, setEmployees] = useState<Employee[]>([]);
  const [nextAssigneeId, setNextAssigneeId] = useState<number | null>(
    transcriptJob.assignedEmployeeId
  );

  const title = `Update Assignee for Job #${transcriptJob.id}`;

  useEffect(() => {
    const fetchEmployees = async () => {
      setLoading(true);
      try {
        const response = await listEmployees();
        setEmployees(response.data || []);
      } catch (error) {
        console.error(error);
        toast.error("Failed to fetch employees");
      } finally {
        setLoading(false);
      }
    };
    fetchEmployees();
  }, []);

  const updateAssignee = async () => {
    setLoading(true);
    try {
      await updateTranscriptJob(transcriptJob.id, {
        assignedEmployeeId: nextAssigneeId,
      });
      await onSave?.();
      toast.success("Job assignee updated");
      closeModal();
    } catch (error) {
      console.error(error);
      toast.error("Failed to update job assignee");
    } finally {
      setLoading(false);
    }
  }

  return (
    <Modal
      title={title}
      dialogue={{
        primary: {
          onClick: updateAssignee,
          title: "Save",
          autoFocus: true,
          loading,
        },
        secondary: { onClick: closeModal },
      }}
    >
      {loading ? (
        <LoadingSpinner />
      ) : (
        <>
          <Box mb={4}>
            <Select
              value={nextAssigneeId || ""}
              onChange={(e) => setNextAssigneeId(Number(e.target.value) || null)}
            >
              <option value="">Unassigned</option>
              {employees.map((employee) => (
                <option key={employee.id} value={employee.id}>
                  {`${employee.firstName} ${employee.lastName}`}
                </option>
              ))}
            </Select>
          </Box>
          <Box>
            <AssigneeBadge
              employee={employees.find(e => e.id === nextAssigneeId) || null}
              transcriptJob={transcriptJob}
            />
          </Box>
        </>
      )}
    </Modal>
  )
}
import { EditorWrapper } from "./EditorWrapper";
import { EditorLoading } from "./EditorLoading";
import { EditorError } from "./EditorError";
import {
  TranscriptJobProvider,
  useTranscriptJob,
} from "src/shared/context/TranscriptJobProvider";
import { EditorHeader } from "./EditorHeader";
import { EditorFooter } from "./EditorFooter";
import "react-h5-audio-player/lib/styles.css";
import "./index.css";
import { EditorContent } from "./EditorContent";
import { EDITOR_PROPERTIES_WIDTH, EditorProperties } from "./EditorProperties";
import { useEffect, useState } from "react";

const TranscriptJobEditorWrapped = () => {
  const {
    loading,
    transcriptJob,
    showJobProperties,
    completeJobLoading,
    abandonJobLoading,
  } = useTranscriptJob();
  const [animate, setAnimate] = useState(true);
  const [editorWidth, setEditorWidth] = useState("100vw");

  useEffect(() => {
    const nextEditorWidth = showJobProperties
      ? `calc(100vw - ${EDITOR_PROPERTIES_WIDTH}px)`
      : "100vw";
    setEditorWidth(nextEditorWidth);
    setAnimate(true);
    setTimeout(() => {
      setAnimate(false);
    }, 350);
  }, [editorWidth, showJobProperties]);

  return (
    <EditorWrapper>
      {loading ? (
        <EditorLoading />
      ) : !transcriptJob?.id ? (
        <EditorError />
      ) : (
        <div className={`editor-container ${showJobProperties ? "with-properties" : ""}`}>
          {(completeJobLoading || abandonJobLoading) && (
            <div
              style={{
                position: "fixed",
                width: "100vw",
                height: "100vh",
                top: 0,
                left: 0,
                zIndex: 100000,
              }}
            />
          )}
          <div
            className="editor-content"
            style={{
              width: editorWidth,
              transition: animate ? "width 0.2s ease" : "none",
            }}
          >
            <EditorHeader />
            <EditorContent />
            <EditorFooter />
          </div>
          <EditorProperties />
        </div>
      )}
    </EditorWrapper>
  );
};

export const TranscriptJobEditor = ({ id }) => {
  return (
    <TranscriptJobProvider id={id}>
      <TranscriptJobEditorWrapped />
    </TranscriptJobProvider>
  );
};

import { useHotkeys } from "react-hotkeys-hook";
import { OptionsOrDependencyArray } from "react-hotkeys-hook/dist/types";

type Shortcut = {
  key: string | string[];
  label?: string;
  description: string;
};

export const hotkeyOptions = {
  enableOnContentEditable: true,
  enableOnFormTags: true,
  preventDefault: true,
};

export enum Action {
  ToggleShortcutsMenu = "ToggleShortcutsMenu",
  OpenCustomerWebsite = "OpenCustomerWebsite",
  OpenJobProperties = "OpenJobProperties",
  CompleteJob = "CompleteJob",
  SendJobToQA = "SendJobToQA",
  SaveJobSnapshot = "SaveJobSnapshot",
  JumpToSubjectLine = "JumpToSubjectLine",
  JumpToTranscriptEditor = "JumpToTranscriptEditor",
  JumpToContact = "JumpToContact",
  JumpToNextLowConfidenceWord = "JumpToNextLowConfidenceWord",
  UndoChanges = "UndoChanges",
  RedoChanges = "RedoChanges",
  BoldText = "BoldText",
  ItalicizeText = "ItalicizeText",
  UnderlineText = "UnderlineText",
  ToggleTextCasing = "ToggleTextCasing",
  PlayPauseAudio = "PlayPauseAudio",
  JumpToBeginningOfAudio = "JumpToBeginningOfAudio",
  JumpTo5SecondsBeforeEndOfAudio = "JumpTo5SecondsBeforeEndOfAudio",
  IncreaseVolume = "IncreaseVolume",
  DecreaseVolume = "DecreaseVolume",
  IncreasePlaybackSpeed = "IncreasePlaybackSpeed",
  DecreasePlaybackSpeed = "DecreasePlaybackSpeed",
  RewindAudio = "RewindAudio",
  FastForwardAudio = "FastForwardAudio",
  ToggleRapidMode = "ToggleRapidMode",
}

export const shortcuts: { [K in Action]: Shortcut } = {
  [Action.ToggleShortcutsMenu]: {
    key: ["F2", "ALT+S"],
    description: "Toggle shortcuts menu",
  },
  [Action.OpenCustomerWebsite]: {
    key: "ALT+W",
    description: "Open customer website",
  },
  [Action.OpenJobProperties]: {
    key: "ALT+D",
    description: "Open job properties",
  },
  [Action.CompleteJob]: {
    key: "ALT+Z",
    description: "Complete job",
  },
  [Action.SendJobToQA]: {
    key: "ALT+Q",
    description: "Send job to QA",
  },
  [Action.SaveJobSnapshot]: {
    key: "ALT+R",
    description: "Save job snapshot (temporary version)",
  },
  [Action.JumpToSubjectLine]: {
    key: "ALT+T",
    description: "Jump to subject line",
  },
  [Action.JumpToTranscriptEditor]: {
    key: "ALT+F",
    description: "Jump to transcript editor",
  },
  [Action.JumpToContact]: {
    key: "ALT+C",
    description: "Jump to contact",
  },
  [Action.JumpToNextLowConfidenceWord]: {
    key: "ALT+J",
    description: "Jump to next low-confidence word",
  },
  [Action.ToggleRapidMode]: {
    key: "ALT+K",
    description: "Toggle Rapid Mode",
  },
  [Action.UndoChanges]: {
    key: "CTRL+Z",
    description: "Undo changes",
  },
  [Action.RedoChanges]: {
    key: "CTRL+Y",
    description: "Redo changes",
  },
  [Action.BoldText]: {
    key: "CTRL+B",
    description: "Bold text",
  },
  [Action.ItalicizeText]: {
    key: "CTRL+I",
    description: "Italicize text",
  },
  [Action.UnderlineText]: {
    key: "CTRL+U",
    description: "Underline text",
  },
  [Action.ToggleTextCasing]: {
    key: "SHIFT+F3",
    description: "Toggle text casing",
  },
  [Action.PlayPauseAudio]: {
    key: "]",
    description: "Play / pause audio",
  },
  [Action.JumpToBeginningOfAudio]: {
    key: "[",
    description: "Jump to beginning of audio",
  },
  [Action.JumpTo5SecondsBeforeEndOfAudio]: {
    key: "\\",
    description: "Jump to 5 seconds before end of audio",
  },
  [Action.IncreaseVolume]: {
    key: "ALT+ArrowRight",
    label: "ALT+→",
    description: "Increase volume",
  },
  [Action.DecreaseVolume]: {
    key: "ALT+ArrowLeft",
    label: "ALT+←",
    description: "Decrease volume",
  },
  [Action.IncreasePlaybackSpeed]: {
    key: "ALT+ArrowUp",
    label: "ALT+↑",
    description: "Increase playback speed",
  },
  [Action.DecreasePlaybackSpeed]: {
    key: "ALT+ArrowDown",
    label: "ALT+↓",
    description: "Decrease playback speed",
  },
  [Action.RewindAudio]: {
    key: "Shift+{",
    description: "Rewind audio 5 seconds",
  },
  [Action.FastForwardAudio]: {
    key: "Shift+}",
    description: "Fast forward audio 5 seconds",
  },
};

export const useShortcut = (
  action: Action,
  callback: () => void,
  options?: OptionsOrDependencyArray,
  dependencies?: OptionsOrDependencyArray
) => {
  const key = shortcuts[action].key;
  return useHotkeys(key, callback, options || hotkeyOptions, dependencies);
};

import { Avatar, Flex } from "@chakra-ui/react";
import { VisibilityState, createColumnHelper } from "@tanstack/react-table";
import { useCallback, useEffect, useMemo, useState } from "react";
import { toast } from "sonner";
import { Employee, listEmployees } from "src/shared/api";
import { DailyGoal } from "src/shared/components/DailyGoal";
import { Table } from "src/shared/components/Table";
import { SearchProvider } from "src/shared/components/Table/SearchContext";
import { stringToColor } from "src/shared/utils";

export type EmployeeRow = {
  id: string;
  fullName: string | null;
  role: string | null;
  dailyLineCountGoal: string | null;
  employee: Employee | null;
};

export const TeamList = () => {
  const [employees, setEmployees] = useState<Employee[]>([]);
  const [loading, setLoading] = useState(true);

  const loadEmployees = useCallback(async () => {
    setLoading(true);
    try {
      const response = await listEmployees({ limit: 1000 });
      setEmployees(response.data || []);
    } catch (e) {
      console.error(e);
      toast.error("Error loading team members");
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    loadEmployees()
  }, [loadEmployees]);

  const employeeRows = useMemo<EmployeeRow[]>(
    () =>
      employees.map(
        (employee): EmployeeRow => ({
          id: employee.id.toString(),
          employee: employee,
          fullName: `${employee.firstName} ${employee.lastName}`,
          role: employee.role,
          dailyLineCountGoal: employee.dailyLineCountGoal?.toString() || "0",
        })
      ),
    [employees]
  );

  const columnHelper = createColumnHelper<EmployeeRow>();
  const columns = useMemo(
    () => [
      columnHelper.accessor("fullName", {
        header: "Full Name",
        minSize: 225,
        cell: (cell) => {
          const employee = employees.find(
            (employee) => employee.id.toString() === cell.row.original.id
          );
          const fullName = cell.getValue() ?? "";
          return (
            <Flex alignItems="center" gap="1">
              <Avatar
                size="xs"
                src={employee?.avatarUri ?? ""}
                bg={stringToColor(fullName)}
              />
              {fullName}
            </Flex>
          );
        },
      }),
      columnHelper.accessor("role", {
        header: "Role",
        minSize: 275,
      }),
      columnHelper.accessor(
        (row): string | null =>
          `${
            (row.employee?.dailyLineCount ?? 0) /
            (row.employee?.dailyLineCountGoal ?? 0)
          }`,
        {
          id: "dailyLineCountGoal",
          header: "Daily Goal",
          minSize: 175,
          cell: (cell) => (
            <DailyGoal employee={cell.row.original.employee} onSave={loadEmployees} />
          ),
        }
      ),
    ],
    [columnHelper, employees, loadEmployees]
  );

  const initialColumnVisibility: Record<keyof EmployeeRow, boolean> &
    VisibilityState = {
    id: false,
    employee: false,
    fullName: true,
    role: true,
    dailyLineCountGoal: true,
  };

  return (
    <SearchProvider>
      <Table
        title="Team"
        data={employeeRows}
        loading={loading}
        columns={columns}
        initialColumnVisibility={initialColumnVisibility}
      />
    </SearchProvider>
  );
};

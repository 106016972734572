import { useModal } from "src/shared/context/ModalProvider";
import Modal from "../Modal";
import { TranscriptJob, TranscriptJobStatus, updateTranscriptJob } from "src/shared/api";
import { useState } from "react";
import { toast } from "sonner";
import { StatusBadge, statusLabelMap } from "../StatusBadge";
import { Box, Select } from "@chakra-ui/react";

interface ModalUpdateJobStatusProps {
  transcriptJob: TranscriptJob;
  onSave?: () => Promise<void> | void;
}

export const ModalUpdateJobStatus = ({
  transcriptJob,
  onSave,
}: ModalUpdateJobStatusProps) => {
  const { closeModal } = useModal();
  const [loading, setLoading] = useState(false);
  const [nextStatus, setNextStatus] = useState<TranscriptJobStatus>(
    transcriptJob.status
  );

  const title = `Update Status for Job #${transcriptJob.id}`;

  const saveTranscriptJob = async () => {
    setLoading(true);
    try {
      await updateTranscriptJob(transcriptJob.id, {
        status: nextStatus,
      });
      await onSave?.();
      toast.success("Job status updated");
      closeModal();
    } catch (error) {
      console.error(error);
      toast.error("Failed to update job status");
    } finally {
      setLoading(false);
    }
  }

  return (
    <Modal
      title={title}
      dialogue={{
        primary: {
          onClick: saveTranscriptJob,
          title: "Save",
          autoFocus: true,
          loading,
        },
        secondary: { onClick: closeModal },
      }}
    >
      <Box mb={4}>
        <Select
          value={nextStatus}
          onChange={(e) => setNextStatus(e.target.value as TranscriptJobStatus)}
        >
          {Object.values(TranscriptJobStatus).map((status) => (
            <option key={status} value={status}>
              {statusLabelMap[status]}
            </option>
          ))}
        </Select>
      </Box>
      <Box>
        <StatusBadge
          readOnly
          transcriptJob={transcriptJob}
          status={nextStatus}
          size="6"
        />
      </Box>
    </Modal>
  );
};

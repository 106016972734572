import React, { useState } from "react";
import { useModal } from "src/shared/context/ModalProvider";
import { TranscriptJob, updateTranscriptJob } from "src/shared/api";
import Modal from "../Modal";
import { VStack, Checkbox, Text } from "@chakra-ui/react";
import { toast } from "sonner";
import { useTranscriptJob } from "src/shared/context/TranscriptJobProvider";

interface ModalSelectEmailRecipientsProps {
  transcriptJob: TranscriptJob;
  emails: Email[];
  onSave?: () => Promise<void> | void;
}

interface Email {
  userId: number;
  name: string;
  emailAddress: string;
  isDefault: number;
  isPrimary: number;
}

export const ModalSelectEmailRecipients: React.FC<ModalSelectEmailRecipientsProps> = ({ transcriptJob, emails, onSave }) => {
  const { closeModal } = useModal()
  const { setRecipientEmails } = useTranscriptJob()
  const [loading, setLoading] = useState(false)
  const [selectedEmails, setSelectedEmails] = useState<string[]>(() => {
    if (typeof transcriptJob.recipientEmails === 'string') {
      try {
        return JSON.parse(transcriptJob.recipientEmails);
      } catch {
        return [];
      }
    }
    return Array.isArray(transcriptJob.recipientEmails) ? transcriptJob.recipientEmails : [];
  })

  const handleCheckboxChange = (emailAddress: string) => {
    setSelectedEmails(prev => {
      if (prev.includes(emailAddress)) {
        return prev.filter(e => e !== emailAddress)
      } else if (prev.length < 5) {
        return [...prev, emailAddress]
      } else {
        toast.error("Maximum of 5 email recipients allowed")
        return prev
      }
    })
  }

  const updateEmailRecipients = async () => {
    setLoading(true)
    try {
      setRecipientEmails(selectedEmails)
      await updateTranscriptJob(transcriptJob.id, {
        recipientEmails: selectedEmails,
      })
      await onSave?.()
      toast.success("Email recipients updated successfully")
      closeModal()
    } catch (error) {
      console.error(error)
      toast.error("Failed to update email recipients")
    } finally {
      setLoading(false)
    }
  };

  return (
    <Modal
      title="Email Recipients"
      dialogue={{
        primary: { onClick: updateEmailRecipients, title: "Save", loading },
        secondary: { onClick: closeModal, title: "Close" },
      }}
    >
      <VStack align="stretch" spacing={4}>
        <Text fontWeight="bold" mb={2}>
          Please select up to 5 recipient email addresses:
        </Text>
        {emails.length > 0 ? (
          emails.map((email) => (
            <Checkbox
              key={email.emailAddress}
              isChecked={selectedEmails.includes(email.emailAddress)}
              onChange={() => handleCheckboxChange(email.emailAddress)}
            >
              <Text fontWeight={email.isPrimary ? "bold" : "normal"}>
                {email.name} ({email.emailAddress})
              </Text>
            </Checkbox>
          ))
        ) : (
          <Text>No email recipients found.</Text>
        )}
      </VStack>
    </Modal>
  )
}

import { claimNextTranscriptJob } from "src/shared/api";
import { Button } from "src/shared/components/Button";
import { TranscriptJobEditor } from "src/shared/components/TranscriptJobEditor";
import { useHistory, useParams } from "react-router-dom";
import { useState } from "react";
import { useAuth } from "src/shared/context/AuthProvider";
import { toast } from "sonner";

interface ClaimNextTranscriptJobProps {
  label?: string;
  disabled?: boolean;
}

export const ClaimNextTranscriptJob = ({
  disabled,
  label,
}: ClaimNextTranscriptJobProps) => {
  const { currentEmployee } = useAuth();
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const onClaimNextJob = async () => {
    if (!currentEmployee?.id) return;

    try {
      setLoading(true);
      const job = await claimNextTranscriptJob(currentEmployee?.id);
      setLoading(false);
      history.push(`/jobs/${job.id}`);
    } catch (e) {
      toast.error("Error claiming job");
    }
  };

  return (
    <Button
      onClick={onClaimNextJob}
      style={{
        width: "fit-content",
        fontSize: "1.1rem",
        padding: "0.75rem 1.25rem",
      }}
      disabled={disabled}
      loading={loading}
      autoFocus
    >
      {label || "Start Next Job"}
    </Button>
  );
};

export const JobDetail = () => {
  const { id } = useParams<{ id: string }>();

  return <TranscriptJobEditor id={id} />;
};

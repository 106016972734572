import { useTranscriptJob } from "src/shared/context/TranscriptJobProvider";
import { JobMetadata } from "./JobMetadata"
import { IconButton, Input, Icon } from "@chakra-ui/react";
import { Action, useShortcut } from "src/shared/shortcuts";
import { MdRemoveCircle } from "react-icons/md";
import { Button } from "../../Button";

const ContactsMetadata = () => {
  const { contacts, setContacts, inProgress } = useTranscriptJob();

  const addContact = () => {
    if (
      contacts.length === 0 ||
      contacts[contacts.length - 1].firstName !== "" ||
      contacts[contacts.length - 1].lastName !== ""
    ) {
      setContacts([...contacts, { firstName: "", lastName: "" }]);
    } else {
      const inputs = document.querySelectorAll(".contact-first-name-input");
      (inputs[inputs.length - 1] as any)?.focus();
    }
  };

  const addOrSelectContact = () => {
    if (!inProgress) return;
    if (contacts.length === 0) {
      setContacts([...contacts, { firstName: "", lastName: "" }]);
    } else {
      const inputs = document.querySelectorAll(".contact-first-name-input");
      (inputs[inputs.length - 1] as any)?.focus();
    }
  };

  useShortcut(Action.JumpToContact, addOrSelectContact);

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}>
      {contacts.map((contact, i) => (
        <div key={i} style={{ display: "flex", gap: "0.5rem" }}>
          <Input
            className="contact-first-name-input"
            placeholder="First name"
            autoFocus
            value={contact.firstName}
            onChange={(e) => {
              const newContacts = [...contacts];
              newContacts[i].firstName = e.target.value;
              setContacts(newContacts);
            }}
            style={{ padding: "0.5rem" }}
          />
          <Input
            placeholder="Last name"
            value={contact.lastName}
            onChange={(e) => {
              const newContacts = [...contacts];
              newContacts[i].lastName = e.target.value;
              setContacts(newContacts);
            }}
            style={{ padding: "0.5rem" }}
          />
          <IconButton
            aria-label="remove"
            onClick={() => {
              const newContacts = [...contacts];
              newContacts.splice(i, 1);
              setContacts(newContacts);
            }}
            sx={{ padding: "0" }}
            icon={<Icon as={MdRemoveCircle} boxSize="5" />}
            variant="ghost"
          />
        </div>
      ))}
      <div>
        <Button secondary onClick={addContact} disabled={!inProgress}>
          Add contact
        </Button>
      </div>
    </div>
  );
};

export const JobContacts = () => {
  return <JobMetadata label="Contacts" value={<ContactsMetadata />} />
}
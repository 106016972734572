import { useEffect, useRef } from "react";
import AudioPlayer, { RHAP_UI } from "react-h5-audio-player";
import { useTranscriptJob } from "src/shared/context/TranscriptJobProvider";
import { Action, hotkeyOptions, useShortcut } from "src/shared/shortcuts";
import { useLocalStorage } from "usehooks-ts";
import { makeLowConfidenceTextSegments } from "./utils";

const EditorAudioHotkeys = ({
  audioTrack,
}: {
  audioTrack: HTMLAudioElement | undefined;
}) => {
  const decreaseVolume = () => {
    if (audioTrack) {
      audioTrack.volume = Math.max(audioTrack.volume - 0.1, 0);
    }
  };

  const increaseVolume = () => {
    if (audioTrack) {
      audioTrack.volume = Math.min(audioTrack.volume + 0.1, 1);
    }
  };

  const togglePlayPause = () => {
    if (audioTrack?.paused) {
      if (audioTrack.currentTime > 1) {
        audioTrack.currentTime = audioTrack.currentTime - 1;
      }
      audioTrack?.play();
    } else {
      audioTrack?.pause();
    }
  };

  const jumpToBeginningOfAudio = () => {
    if (audioTrack) {
      audioTrack.currentTime = 0;
      audioTrack?.play();
    }
  };

  const jumpTo5SecondsBeforeEndOfAudio = () => {
    if (audioTrack) {
      audioTrack.currentTime = audioTrack.duration - 5;
      audioTrack?.play();
    }
  };

  const rewindAudio5Seconds = () => {
    if (audioTrack) {
      audioTrack.currentTime = Math.max(audioTrack.currentTime - 5, 0);
    }
  };

  const fastForwardAudio5Seconds = () => {
    if (audioTrack) {
      audioTrack.currentTime = Math.min(
        audioTrack.currentTime + 5,
        audioTrack.duration
      );
    }
  };

  useShortcut(Action.PlayPauseAudio, togglePlayPause, hotkeyOptions, [
    audioTrack,
  ]);
  useShortcut(
    Action.JumpToBeginningOfAudio,
    jumpToBeginningOfAudio,
    hotkeyOptions,
    [audioTrack]
  );
  useShortcut(
    Action.JumpTo5SecondsBeforeEndOfAudio,
    jumpTo5SecondsBeforeEndOfAudio,
    hotkeyOptions,
    [audioTrack]
  );
  useShortcut(Action.DecreaseVolume, decreaseVolume, hotkeyOptions, [
    audioTrack,
  ]);
  useShortcut(Action.IncreaseVolume, increaseVolume, hotkeyOptions, [
    audioTrack,
  ]);

  useShortcut(
    Action.FastForwardAudio,
    fastForwardAudio5Seconds,
    hotkeyOptions,
    [audioTrack]
  );
  useShortcut(Action.RewindAudio, rewindAudio5Seconds, hotkeyOptions, [
    audioTrack,
  ]);

  return null;
};

const PlaybackRateControl = ({
  audioTrack,
}: {
  audioTrack: HTMLAudioElement | undefined;
}) => {
  const [playbackRate, setPlaybackRate] = useLocalStorage("playbackRate", 1);
  const playbackRates = [0.5, 0.75, 1, 1.25, 1.5, 2];

  const increasePlaybackRate = () => {
    const currentIndex = playbackRates.indexOf(playbackRate);
    const nextIndex = currentIndex + 1;

    if (nextIndex === playbackRates.length) {
      return;
    }

    setPlaybackRate(playbackRates[nextIndex] || 2);
  };

  const decreasePlaybackRate = () => {
    const currentIndex = playbackRates.indexOf(playbackRate);
    const nextIndex = currentIndex - 1;

    if (nextIndex === -1) {
      return;
    }

    setPlaybackRate(playbackRates[nextIndex] || 0.5);
  };

  const cyclePlaybackRate = () => {
    const currentIndex = playbackRates.indexOf(playbackRate);
    const nextIndex = (currentIndex + 1) % playbackRates.length;

    setPlaybackRate(playbackRates[nextIndex]);
  };

  useShortcut(
    Action.IncreasePlaybackSpeed,
    increasePlaybackRate,
    hotkeyOptions,
    [audioTrack, playbackRate]
  );
  useShortcut(
    Action.DecreasePlaybackSpeed,
    decreasePlaybackRate,
    hotkeyOptions,
    [audioTrack, playbackRate]
  );

  useEffect(() => {
    if (audioTrack) {
      audioTrack.playbackRate = playbackRate;
    }
  }, [playbackRate, audioTrack]);

  return (
    <div className="playback-rate-control" onClick={cyclePlaybackRate}>
      {playbackRate}x
    </div>
  );
};

const BUFFER_TIME = 0.5;

export const EditorAudio = () => {
  const { transcriptJob, transcriptVersion, isRapidMode } = useTranscriptJob();

  const audioRef = useRef(null);
  const audioTrack = (audioRef?.current as any)?.audio?.current as
    | HTMLAudioElement
    | undefined;

  const segments = makeLowConfidenceTextSegments(
    transcriptVersion?.textWithTimestamps
  );

  const skipToNextSegment = () => {
    if (!audioTrack) {
      return;
    }
    const currentTime = audioTrack.currentTime;
    const currentSegment = segments.find(
      (segment) =>
        segment.start <= currentTime + BUFFER_TIME &&
        segment.end >= currentTime - BUFFER_TIME
    );

    if (currentSegment) {
      return;
    }
    const nextSegment = segments.find(
      (segment) => segment.start >= currentTime
    );
    if (nextSegment) {
      audioTrack.currentTime = nextSegment.start - BUFFER_TIME / 2;
    }
    const lastSegment = segments[segments.length - 1];
    if (lastSegment && currentTime >= lastSegment.end + BUFFER_TIME) {
      audioTrack.pause();
    }
  };

  return (
    <>
      <AudioPlayer
        ref={audioRef}
        showFilledVolume
        layout="horizontal-reverse"
        src={transcriptJob?.audio?.url || ""}
        customVolumeControls={[
          <PlaybackRateControl audioTrack={audioTrack} />,
          RHAP_UI.VOLUME,
        ]}
        autoPlay={false}
        hasDefaultKeyBindings={false}
        listenInterval={100}
        onListen={() => {
          if (isRapidMode) {
            skipToNextSegment();
          }
        }}
      />
      {audioTrack && <EditorAudioHotkeys audioTrack={audioTrack} />}
    </>
  );
};

import { useModal } from "src/shared/context/ModalProvider";
import Modal from "../Modal";
import { TranscriptJob, updateTranscriptJob } from "src/shared/api";
import Input from "../Input";
import { useState } from "react";
import { toast } from "sonner";
import { PriorityBadge } from "../PriorityBadge";
import { Box, HStack } from "@chakra-ui/react";

interface ModalUpdateJobPriorityProps {
  transcriptJob: TranscriptJob;
  onSave?: () => Promise<void> | void;
}

export const ModalUpdateJobPriority = ({
  transcriptJob,
  onSave,
}: ModalUpdateJobPriorityProps) => {
  const { closeModal } = useModal();
  const [loading, setLoading] = useState(false);
  const [nextPriority, setNextPriority] = useState(
    transcriptJob.priority
  );

  const title = `Update Priority for Job #${transcriptJob.id}`;

  const saveTranscriptJob = async () => {
    setLoading(true);
    await updateTranscriptJob(transcriptJob.id, {
      priority: nextPriority,
    });
    await onSave?.();
    toast.success("Job priority updated");
    setLoading(false);
    closeModal();
  }

  return (
    <Modal
      title={title}
      dialogue={{
        primary: {
          onClick: saveTranscriptJob,
          title: "Save",
          autoFocus: true,
          loading,
        },
        secondary: { onClick: closeModal },
      }}
    >
      <HStack>
        <Box flex="3">
          <Input
            value={nextPriority}
            type="number"
            onChange={(e) => {
              if (e.target.value < 0) {
                setNextPriority(0);
                return;
              }
              setNextPriority(e.target.value)
            }}
          />
        </Box>
        <Box flex="1">
          <PriorityBadge readOnly transcriptJob={transcriptJob} priority={nextPriority || 0} />
        </Box>
      </HStack>
    </Modal>
  );
};

import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useHistory } from "react-router-dom";
import {
  TranscriptJob,
  TranscriptJobStatus,
  TranscriptVersion,
  abandonTranscriptJob,
  completeTranscriptJob,
  createTranscriptVersion,
  getTranscriptJob,
  getTranscriptJobLatestTranscript,
  sendTranscriptJobToQa,
  updateTranscriptVersion,
} from "src/shared/api";
import { useAuth } from "../AuthProvider";
import { useModal } from "../ModalProvider";
import { ModalCompleteJob } from "src/shared/components/ModalCompleteJob";
import { ModalAbandonJob } from "src/shared/components/ModalAbandonJob";
import { Action, useShortcut } from "src/shared/shortcuts";
import { useInterval } from "usehooks-ts";
import { toast } from "sonner";
import {
  makeJsonContent,
  makeTextContent,
} from "src/shared/components/TranscriptJobEditor/utils";
import { JSONContent } from "@tiptap/react";
import { ModalSendJobToQa } from "src/shared/components/ModalSendJobToQa";
import { logTranscriptJobAbandoned } from "src/shared/logger";

export type Contact = {
  firstName: string;
  lastName: string;
};

interface TranscriptJobContextProps {
  transcriptJob: TranscriptJob | null;
  transcriptVersion: TranscriptVersion | null;
  loading: boolean;
  completeJob: () => void;
  completeJobLoading?: boolean;
  abandonJob: () => void;
  abandonJobLoading?: boolean;
  sendJobToQa: () => void;
  content: JSONContent | null;
  setContent: (content: JSONContent) => void;
  title: string;
  setTitle: (title: string) => void;
  showJobProperties: boolean;
  setShowJobProperties: (show: boolean) => void;
  contacts: Contact[];
  setContacts: (contacts: Contact[]) => void;
  recipientEmails: string[];
  setRecipientEmails: (recipientEmails: string[]) => void;
  isRapidMode: boolean;
  setIsRapidMode: (isRapidMode: boolean) => void;
  inProgress: boolean;
  reloadJob: () => void;
}

export const TranscriptJobContext = createContext<TranscriptJobContextProps>({
  transcriptJob: null,
  transcriptVersion: null,
  loading: true,
  completeJob: () => {},
  completeJobLoading: false,
  abandonJob: () => {},
  abandonJobLoading: false,
  sendJobToQa: () => {},
  content: { type: "doc", content: [] },
  setContent: () => {},
  title: "",
  setTitle: () => {},
  showJobProperties: false,
  setShowJobProperties: () => {},
  contacts: [],
  setContacts: () => {},
  recipientEmails: [],
  setRecipientEmails: () => {},
  isRapidMode: false,
  setIsRapidMode: () => {},
  inProgress: false,
  reloadJob: () => {},
});

export const TranscriptJobProvider = ({ id, children }) => {
  const { openModal } = useModal();
  const { currentEmployee, refreshUser } = useAuth();
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [showJobProperties, setShowJobProperties] = useState(true);
  const [completeJobLoading, setCompleteJobLoading] = useState(false);
  const [abandonJobLoading, setAbandonJobLoading] = useState(false);
  const [transcriptJob, setTranscriptJob] = useState<TranscriptJob | null>(
    null
  );
  const [transcriptVersion, setTranscriptVersion] =
    useState<TranscriptVersion | null>(null);
  const [isRapidMode, setIsRapidMode] = useState(false);

  const getContactsFromMetadata = (
    transcriptVersion: TranscriptVersion | null
  ) => {
    try {
      const metadata = JSON.parse(transcriptVersion?.metadataJson || "{}");
      return metadata.contacts || [];
    } catch (e) {
      return [];
    }
  };

  const [content, setContent] = useState<JSONContent | null>(null);
  const [title, setTitle] = useState<string>("");
  const [contacts, setContacts] = useState<Contact[]>([]);
  const [recipientEmails, setRecipientEmails] = useState<string[]>([]);

  const openCustomerWebsite = () => {
    window.open(
      transcriptJob?.customerUser?.companyWebsite || "https://google.com",
      "_blank"
    );
  };

  const toggleJobProperties = () => {
    setShowJobProperties(!showJobProperties);
  };

  const loadJob = useCallback(async () => {
    if (!id) return;
    try {
      const transcriptJobResult = await getTranscriptJob(parseInt(id));
      const transcriptResult = await getTranscriptJobLatestTranscript(
        parseInt(id)
      );

      const nextTranscriptVersion = transcriptResult.data?.[0];

      setTranscriptJob(transcriptJobResult);
      setTranscriptVersion(nextTranscriptVersion);

      setContent(
        makeJsonContent(
          nextTranscriptVersion?.text,
          nextTranscriptVersion?.textWithTimestamps
        )
      );
      setTitle(nextTranscriptVersion?.headline || "");
      setContacts(getContactsFromMetadata(nextTranscriptVersion));

      setLoading(false);
    } catch (e) {
      console.error(e);
      toast.error("Error loading job");
    }
  }, [id]);

  useEffect(() => {
    if (!id) return;
    loadJob();
  }, [id, loadJob]);

  const resetJob = () => {
    setTranscriptJob(null);
    setTranscriptVersion(null);
    setContent(null);
    setTitle("");
    setContacts([]);
  };

  const completeJob = async () => {
    if (!transcriptJob || !currentEmployee?.id) return;
    setCompleteJobLoading(true);
    await completeTranscriptJob(transcriptJob?.id, {
      headline: title !== "Subject Line" ? title : "MobileAssistant",
      text: makeTextContent(content),
      metadataJson: JSON.stringify({ contacts }),
      createdByEmployeeId: currentEmployee?.id,
      versionStatus: "completed",
    });
    await refreshUser();
    resetJob();
    setCompleteJobLoading(false);
    toast.success("Job completed");
    history.push("/");
  };

  const sendJobToQa = async (comments?: string) => {
    if (!transcriptJob || !currentEmployee?.id) return;
    setCompleteJobLoading(true);
    await sendTranscriptJobToQa(
      transcriptJob?.id,
      {
        headline: title,
        text: makeTextContent(content),
        metadataJson: JSON.stringify({ contacts }),
        createdByEmployeeId: currentEmployee?.id,
        versionStatus: "sent_to_qa",
      },
      transcriptJob?.recipientEmails,
      comments,
    );
    await refreshUser();
    resetJob();
    setCompleteJobLoading(false);
    toast.success("Job sent to QA");
    history.push("/");
  };

  const confirmCompleteJob = () => {
    if (!transcriptJob) return;
    openModal(
      <ModalCompleteJob
        completeJob={completeJob}
        transcriptJob={transcriptJob}
      />
    );
  };

  const confirmSendJobToQA = () => {
    if (!transcriptJob) return;
    openModal(
      <ModalSendJobToQa
        sendJobToQa={sendJobToQa}
        transcriptJob={transcriptJob}
      />
    );
  };

  const confirmAbandonJob = () => {
    if (!transcriptJob) return;
    openModal(
      <ModalAbandonJob abandonJob={abandonJob} transcriptJob={transcriptJob} />
    );
  };

  const abandonJob = async () => {
    if (!transcriptJob) return;
    const nextStatus =
      transcriptJob.status === TranscriptJobStatus.InReview
        ? TranscriptJobStatus.InReview
        : TranscriptJobStatus.Pending;
    setAbandonJobLoading(true);
    await abandonTranscriptJob(transcriptJob, nextStatus);
    logTranscriptJobAbandoned(transcriptJob.id);
    resetJob();
    setAbandonJobLoading(false);
    toast.success("Job abandoned");
    history.push("/");
  };

  const inProgress =
    (transcriptJob?.status === TranscriptJobStatus.InProgress ||
      transcriptJob?.status === TranscriptJobStatus.InReview) &&
    currentEmployee?.id === transcriptJob?.assignedEmployeeId;

  const saveTranscriptSnapshot = async () => {
    if (!inProgress) {
      throw new Error(
        "Cannot save snapshot when not in progress. Please claim job first."
      );
    }

    const shouldCreateTranscriptVersion =
      (!transcriptVersion && currentEmployee?.id) ||
      (transcriptVersion?.versionStatus === "original" && currentEmployee?.id) ||
      (transcriptVersion?.versionStatus === "sent_to_qa" && currentEmployee?.id);
    const shouldUpdateTranscriptVersion =
      !!transcriptVersion && currentEmployee?.id;

    const nextTranscriptVersion: Partial<TranscriptVersion> = {
      headline: title,
      text: makeTextContent(content),
      metadataJson: JSON.stringify({ contacts }),
      createdByEmployeeId: currentEmployee?.id,
    };

    if (shouldCreateTranscriptVersion) {
      const createdVersion = await createTranscriptVersion(parseInt(id), nextTranscriptVersion);
      setTranscriptVersion(createdVersion);
    } else if (shouldUpdateTranscriptVersion) {
      const updatedVersion = await updateTranscriptVersion(
        parseInt(id),
        transcriptVersion?.id,
        nextTranscriptVersion
      );
      setTranscriptVersion(updatedVersion);
    }
  };

  const saveTranscriptSnapshotAndNotify = async () => {
    try {
      await saveTranscriptSnapshot();
      toast.success("Job snapshot saved!");
    } catch (e) {
      toast.error((e as any)?.message ?? "Error saving job snapshot");
    }
  };

  useShortcut(Action.OpenJobProperties, toggleJobProperties);
  useShortcut(Action.CompleteJob, confirmCompleteJob);
  useShortcut(Action.OpenCustomerWebsite, openCustomerWebsite);
  useShortcut(Action.SaveJobSnapshot, saveTranscriptSnapshotAndNotify);
  useShortcut(Action.SendJobToQA, confirmSendJobToQA);

  useInterval(async () => {
    try {
      await saveTranscriptSnapshot();
    } catch (e) {
      console.error(e);
    }
  }, 30000);

  return (
    <TranscriptJobContext.Provider
      value={{
        loading,
        transcriptVersion,
        transcriptJob,
        sendJobToQa: confirmSendJobToQA,
        completeJob: confirmCompleteJob,
        completeJobLoading,
        abandonJob: confirmAbandonJob,
        abandonJobLoading,
        content,
        setContent,
        title,
        setTitle,
        contacts,
        setContacts,
        recipientEmails,
        setRecipientEmails,
        showJobProperties,
        setShowJobProperties,
        isRapidMode,
        setIsRapidMode,
        inProgress,
        reloadJob: loadJob,
      }}
    >
      {children}
    </TranscriptJobContext.Provider>
  );
};

export const useTranscriptJob = () => useContext(TranscriptJobContext);

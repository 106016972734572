import { ReactComponent as Logo } from "src/shared/assets/logo.svg";
import { NavLinkButton } from "../Button/index";
import { SidebarWrapper, NavItem, TalkItTitle } from "./index.styles";
import { Link } from "../Text";
import { getSidebarRoutes } from "src/shared/routes";
import { colors } from "src/shared/colors";
import { useAuth } from "src/shared/context/AuthProvider";
import { isAdmin } from "src/shared/utils";

function Sidebar(props) {
  const { currentEmployee } = useAuth();
  const navClass = "ExpandedNav";
  const navItemClass = ["expandedButton"];

  return (
    <SidebarWrapper {...props}>
      <nav className={navClass}>
        <TalkItTitle className="talkItTitle">
          <Link to="/">
            <Logo />
            <h1>Scribe</h1>
          </Link>
        </TalkItTitle>
        <ul>
          {getSidebarRoutes(isAdmin(currentEmployee)).map((item, index) => (
            <NavItem key={index} title={item.title}>
              <NavLinkButton
                className={navItemClass}
                data-testid={item.title}
                iconColor={colors.BLACK}
                iconName={item.icon}
                iconSize={1.25}
                to={item.to}
                exact={item.exact}
                text
              >
                {item.title}
              </NavLinkButton>
            </NavItem>
          ))}
        </ul>
      </nav>
    </SidebarWrapper>
  );
}

export default Sidebar;

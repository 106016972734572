import { Link } from "@chakra-ui/react";
import { JobMetadata } from "./JobMetadata";
import { useTranscriptJob } from "src/shared/context/TranscriptJobProvider";

export const JobCustomerWebsite = () => {
  const { transcriptJob } = useTranscriptJob();

  if (!transcriptJob?.customerUser?.companyWebsite) {
    return null;
  }

  return (
    <JobMetadata
      label="Customer website"
      value={
        <Link
          href={transcriptJob?.customerUser?.companyWebsite}
          target="_blank"
          rel="noopener"
        >
          {transcriptJob?.customerUser?.companyWebsite}
        </Link>
      }
    />
  );
};

import { TranscriptReviewJob } from "src/shared/api";

const downloadAsCsv = async (transcriptReviewJobs: TranscriptReviewJob[]) => {
  const headerRow = ["Job ID", "Status", "Priority", "Sent to QA from", "Created On", "Audio Length", "User ID", "First Name", "Last Name", "Transcribe Start", "Transcription Sent", "Comments"];
  const csvRows = [headerRow, ...transcriptReviewJobs.map(makeCsvRow)];
  const csvContent = csvRows.map(e => e.join(",")).join("\n");
  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
  const url = URL.createObjectURL(blob);

  const link = document.createElement("a");
  link.href = url;
  link.download = `${getFormattedDate()}_job_list.csv`;
  link.click();
}

const getFormattedDate = () => {
  const date = new Date();
  const year = date.getFullYear();
  const month = ("0" + (date.getMonth() + 1)).slice(-2);
  const day = ("0" + date.getDate()).slice(-2);
  return "" + year + month + day;
}

const makeCsvRow = (transcriptReviewJob: any): string[] => {
  const removeNewlineChars = (field: any): string => {
    if (field === null || field === undefined) return '';

    const stringField = String(field);
    if (stringField.includes('\n')) {
      return `"${stringField.replace(/"/g, '""')}"`;
    }

    return stringField;
  };

  return [
    String(transcriptReviewJob.id),
    transcriptReviewJob.status,
    transcriptReviewJob.priority,
    transcriptReviewJob.createdByEmployee ? `${transcriptReviewJob?.createdByEmployee?.firstName} ${transcriptReviewJob?.createdByEmployee?.lastName}` : 'Unassigned',
    transcriptReviewJob.createdOn,
    transcriptReviewJob.audioLengthInSeconds,
    transcriptReviewJob.customerUserId,
    transcriptReviewJob.firstName,
    transcriptReviewJob.lastName,
    transcriptReviewJob.transcriptionStart,
    transcriptReviewJob.transcriptionEnd,
    removeNewlineChars(transcriptReviewJob.comments)
  ]
}

export {
  downloadAsCsv
}
import { MdInfo } from "react-icons/md";
import { EditorAudio } from "./EditorAudio";
import { IconButton } from "@chakra-ui/react";
import { useTranscriptJob } from "src/shared/context/TranscriptJobProvider";

export const EditorFooter = () => {
  const { showJobProperties, setShowJobProperties } = useTranscriptJob();

  return (
    <div
      style={{
        borderTop: "1px solid #ddd",
        backgroundColor: "#fff",
        bottom: "0",
        left: "0",
        width: "100%",
        padding: "1rem",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginTop: "0.5rem",
      }}
    >
      <EditorAudio />
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "0.5rem",
          marginLeft: "6rem",
        }}
      >
        <IconButton aria-label="properties" onClick={() => setShowJobProperties(!showJobProperties)} variant="ghost">
          <MdInfo fontSize="1.5rem" />
        </IconButton>
      </div>
    </div>
  );
};

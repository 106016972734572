import { useAuth } from "src/shared/context/AuthProvider";
import { AssigneeBadge } from "../../AssigneeBadge";
import { JobMetadata } from "./JobMetadata";
import { useTranscriptJob } from "src/shared/context/TranscriptJobProvider";
import { isAdmin } from "src/shared/utils";

export const JobAssignee = () => {
  const { currentEmployee } = useAuth();
  const { transcriptJob, reloadJob } = useTranscriptJob();

  if (!transcriptJob || !isAdmin(currentEmployee)) {
    return null;
  }

  return (
    <JobMetadata
      label="Assigned to"
      value={
        <AssigneeBadge 
          employee={transcriptJob?.assignedEmployee} 
          transcriptJob={transcriptJob}
          onSave={reloadJob}
        />
      }
    />
  );
};

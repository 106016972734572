import { Flex, HStack, Box, Tooltip } from "@chakra-ui/react";
import { VisibilityState, createColumnHelper } from "@tanstack/react-table";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { Link } from "react-router-dom";
import { toast } from "sonner";
import {
  Employee,
  TranscriptJob,
  TranscriptReviewJob,
  TranscriptJobStatus,
  listReviewJobs,
} from "src/shared/api";
import { AssigneeBadge } from "src/shared/components/AssigneeBadge";
import { Button } from "src/shared/components/Button";
import { DropdownMenu } from "src/shared/components/DropdownMenu";
import { ModalJumpToJob } from "src/shared/components/ModalJumpToJob";
import {
  PriorityBadge,
  priorityMap,
} from "src/shared/components/PriorityBadge";
import {
  StatusBadge,
  statusLabelMap,
  statusValueMap,
} from "src/shared/components/StatusBadge";
import { Table } from "src/shared/components/Table";
import { SearchProvider } from "src/shared/components/Table/SearchContext";
import { useModal } from "src/shared/context/ModalProvider";
import { downloadAsCsv } from "./utils"

export type TranscriptReviewJobRow = {
  id: string;
  jobId: string | null;
  status: string | null;
  priority: string | null;
  age: string | null;
  userId: string | null;
  firstName: string | null;
  lastName: string | null;
  audioLength: string | null;
  createdByEmployee: Employee | null;
  comments: string | null;
  transcriptionStart: string | null;
  transcriptionEnd: string | null;
  transcriptReviewJob: TranscriptJob;
};

export const ReviewList = () => {
  const { openModal } = useModal();
  const [transcriptReviewJobs, setTranscriptReviewJobs] = useState<TranscriptReviewJob[]>([]);
  const [loading, setLoading] = useState(true);
  const [status, setStatus] = useState<TranscriptJobStatus>(
    TranscriptJobStatus.InReview
  );

  const loadTranscriptJobs = useCallback(async () => {
    try {
      setLoading(true);
      const statusOrNotStatus = status
        ? { status }
        : { notStatus: TranscriptJobStatus.Completed };
      const response = await listReviewJobs({
        limit: 100_000,
        ...statusOrNotStatus,
      });
      setTranscriptReviewJobs(response.data || []);
    } catch (e) {
      console.error(e);
      toast.error("Error loading jobs");
    } finally {
      setLoading(false);
    }
  }, [status]);

  useEffect(() => {
    loadTranscriptJobs();
  }, [loadTranscriptJobs]);

  const transcriptJobRows = useMemo<TranscriptReviewJobRow[]>(
    () =>
      transcriptReviewJobs.map(
        (transcriptReviewJob): TranscriptReviewJobRow => ({
          id: transcriptReviewJob.id.toString(),
          jobId: transcriptReviewJob.id.toString(),
          status: transcriptReviewJob.status,
          priority: transcriptReviewJob.priority?.toString(),
          age: new Date(transcriptReviewJob?.createdOn).toLocaleString(),
          createdByEmployee: transcriptReviewJob?.createdByEmployee,
          userId: transcriptReviewJob.customerUserId.toString(),
          firstName: transcriptReviewJob.firstName,
          lastName: transcriptReviewJob.lastName,
          audioLength: transcriptReviewJob.audioLengthInSeconds.toString(),
          comments: transcriptReviewJob.comments,
          transcriptReviewJob,
          transcriptionStart: transcriptReviewJob?.transcriptionStart ? new Date(transcriptReviewJob.transcriptionStart).toLocaleString() : null,
          transcriptionEnd: transcriptReviewJob?.transcriptionEnd ? new Date(transcriptReviewJob.transcriptionEnd).toLocaleString() : null,
        })
      ),
    [transcriptReviewJobs]
  );

  const presortedTranscriptJobRows = useMemo(() => {
    if (status === TranscriptJobStatus.Completed) {
      return transcriptJobRows.sort((a, b) => {
        return parseInt(b.id) - parseInt(a.id);
      });
    }
    return transcriptJobRows.sort((a, b) => {
      const priorityA = parseInt(a.priority || "1");
      const priorityB = parseInt(b.priority || "1");

      if (priorityA === priorityB) {
        return parseInt(a.id) - parseInt(b.id);
      }
      return priorityB - priorityA;
    });
  }, [transcriptJobRows, status]);

  const columnHelper = createColumnHelper<TranscriptReviewJobRow>();
  const columns = useMemo(
    () => [
      columnHelper.accessor("jobId", {
        header: "Job ID",
        maxSize: 100,
        cell: (cell) => (
          <Link to={`/jobs/${cell.getValue()}`}>
            <Flex alignItems="center" gap="1" fontWeight="bold">
              {cell.getValue()}
            </Flex>
          </Link>
        ),
      }),
      columnHelper.accessor(
        (cell): string | null =>
          `${statusValueMap[cell.status ?? "failed"]} ${
            cell.status ?? "failed"
          }`,
        {
          id: "status",
          header: "Status",
          maxSize: 115,
          cell: (cell) => (
            <StatusBadge 
              status={cell.row.original.status || "pending"}
              transcriptJob={cell.row.original.transcriptReviewJob}
              onSave={loadTranscriptJobs}
            />
          ),
        }
      ),
      columnHelper.accessor(
        (row) => priorityMap[parseInt(row.priority || "1")],
        {
          id: "priority",
          header: "Priority",
          maxSize: 100,
          cell: (cell) => (
            <PriorityBadge
              onSave={loadTranscriptJobs}
              transcriptJob={cell.row.original.transcriptReviewJob}
              priority={parseInt(cell.row.original.priority || "1")}
            />
          ),
        }
      ),
      columnHelper.accessor((row) => row.createdByEmployee?.firstName, {
        id: "createdByEmployee",
        header: "Sent to QA from",
        minSize: 200,
        cell: (cell) => (
          <AssigneeBadge 
            employee={cell.row.original.createdByEmployee} 
            transcriptJob={cell.row.original.transcriptReviewJob}
          />
        ),
      }),
      columnHelper.accessor("age", {
        header: "Created on",
        minSize: 180,
        cell: (cell) => (
          <Flex alignItems="center">
            {cell.getValue()}
          </Flex>
        ),
      }),
      columnHelper.accessor("audioLength", {
        header: "Length",
        maxSize: 85,
        cell: (cell) => (
          <Flex alignItems="center">
            {Math.floor(Number(cell.getValue()) / 60)}:
            {String(Number(cell.getValue()) % 60).padStart(2, '0')}
          </Flex>
        ),
    }),
      columnHelper.accessor("userId", {
        header: "User ID",
        maxSize: 85,
        cell: (cell) => (
          <Flex alignItems="center">
            {cell.getValue()}
          </Flex>
        ),
      }),
      columnHelper.accessor("firstName", {
        header: "First Name",
        minSize: 120,
        cell: (cell) => (
          <Flex alignItems="center">
            {cell.getValue()}
          </Flex>
        ),
      }),
      columnHelper.accessor("lastName", {
        header: "Last Name",
        minSize: 160,
        cell: (cell) => (
          <Flex alignItems="center">
            {cell.getValue()}
          </Flex>
        ),
      }),
      columnHelper.accessor("transcriptionStart", {
        header: "Transcribe Start",
        minSize: 180,
        cell: (cell) => (
          <Flex alignItems="center">
            {cell.getValue()}
          </Flex>
        ),
      }),
      columnHelper.accessor("transcriptionEnd", {
        header: "Transcription Sent",
        minSize: 180,
        cell: (cell) => (
          <Flex alignItems="center">
            {cell.getValue()}
          </Flex>
        ),
      }),
      columnHelper.accessor("comments", {
        header: "Comments",
        minSize: 160,
        cell: (cell) => (
          <Tooltip 
            label={cell.getValue() || ''}
            openDelay={100}
            placement="top"
            hasArrow
          >
            <Flex alignItems="center">
              <Box
                maxWidth="100%"
                overflow="hidden"
                textOverflow="ellipsis"
                whiteSpace="nowrap"
              >
                {cell.getValue()}
              </Box>
            </Flex>
          </Tooltip>
        ),
      }),
    ],
    [columnHelper, loadTranscriptJobs]
  );

  const initialColumnVisibility: Record<keyof TranscriptReviewJobRow, boolean> &
    VisibilityState = {
    id: false,
    jobId: true,
    status: true,
    priority: true,
    age: true,
    createdByEmployee: true,
    audioLength: true,
    userId: true,
    firstName: true,
    lastName: true,
    comments: true,
    transcriptReviewJob: false,
    transcriptionStart: true,
    transcriptionEnd: true,
  };

  const makeStatusOptions = () => {
    const options = Object.values(TranscriptJobStatus).map((status) => ({
      label: statusLabelMap[status],
      value: status,
    }));
    return options;
  };

  const openModalJumpToJob = () => {
    openModal(<ModalJumpToJob />);
  };

  useHotkeys("alt+f", openModalJumpToJob);
  useHotkeys("alt+r", loadTranscriptJobs);

  return (
    <SearchProvider>
      <Table
        title="In Review"
        data={presortedTranscriptJobRows}
        loading={loading}
        columns={columns}
        leftActions={
          <DropdownMenu
            options={makeStatusOptions()}
            value={{ label: statusLabelMap[status], value: status }}
            onChange={(option) =>
              setStatus(option.value as TranscriptJobStatus)
            }
          />
        }
        rightActions={
        <HStack>
          <Button onClick={loadTranscriptJobs} secondary size="sm">
            Refresh Jobs
          </Button>
          <Button onClick={() => downloadAsCsv(transcriptReviewJobs)} secondary size="sm">
            Download CSV
          </Button>
          <Button onClick={openModalJumpToJob} size="sm">
            Find Job
          </Button>
          </HStack>
        }
        initialColumnVisibility={initialColumnVisibility}
      />
    </SearchProvider>
  );
};

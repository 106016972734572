import ColorHash from 'color-hash'
import { Employee, EmployeeRole } from './api'

export function stringToColor (str: string): string {
  const colorHash = new ColorHash({ lightness: 0.5, saturation: 0.3, hue: { min: 50, max: 360 } })
  return colorHash.hex(str)
}

export const isAdmin = (employee?: Employee | null) => {
  return employee?.role === EmployeeRole.Admin
}
import { Box, Heading } from "@chakra-ui/react";

interface PageLayoutProps {
  title: string;
  actions?: React.ReactNode;
  children: React.ReactNode;
}

export function PageLayout({ title, children }: PageLayoutProps) {
  return (
    <Box p="6">
      <Heading size="md" mb="8">{title}</Heading>
      {children}
    </Box>
  );
}

import { useModal } from "src/shared/context/ModalProvider";
import Modal from "../Modal";
import { shortcuts } from "src/shared/shortcuts";
import { colors } from "src/shared/colors";

export const ModalEditorSettings = () => {
  const { closeModal } = useModal();

  return (
    <Modal
      title="Shortcuts"
      dialogue={{
        secondary: { onClick: closeModal, title: "Close" },
      }}
    >
      <div style={{ display: "flex", flexDirection: "column" }}>
        {Object.values(shortcuts).map((shortcut) => {
          const key =
            shortcut.key instanceof Array
              ? shortcut.key.join(" + ")
              : shortcut.key;

          return (
            <div
              key={key}
              style={{
                display: "flex",
                justifyContent: "space-between",
                borderBottom: "1px solid #ddd",
                alignItems: "center",
                padding: "0.5rem 0",
              }}
            >
              <div>{shortcut.description}</div>
              <div
                style={{
                  backgroundColor: colors.GREY_200,
                  fontFamily: "monospace",
                  padding: "0.25rem",
                  borderRadius: "5px",
                  fontWeight: "bold",
                }}
              >
                {shortcut.label
                  ? shortcut.label
                  : shortcut.key instanceof Array
                  ? shortcut.key[0]
                  : shortcut.key}
              </div>
            </div>
          );
        })}
      </div>
    </Modal>
  );
};

import { Link } from "react-router-dom";
import { H3 } from "../Text";
import { Text } from "@chakra-ui/react";

export const EditorError = () => {
  return (
    <div style={{ padding: "1rem" }}>
      <H3>Error loading job</H3>
      <Text mb="4">There was an error loading the job. Either it doesn't exist, or some other issue came up.</Text>
      <Link to="/">Back to home</Link>
    </div>
  );
};

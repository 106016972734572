import { useModal } from "src/shared/context/ModalProvider";
import Modal from "../Modal";
import { AudioRating } from "../AudioRating.tsx";
import { TranscriptJob } from "src/shared/api";

interface ModalAbandonJobProps {
  abandonJob: () => Promise<void>;
  transcriptJob: TranscriptJob;
}

export const ModalAbandonJob = ({
  abandonJob,
  transcriptJob,
}: ModalAbandonJobProps) => {
  const { closeModal } = useModal();
  return (
    <Modal
      title="Are you sure?"
      dialogue={{
        primary: {
          onClick: () => {
            abandonJob();
            closeModal();
          },
          title: "Abandon Job",
          autoFocus: true,
        },
        secondary: { onClick: closeModal },
      }}
    >
      <AudioRating transcriptJob={transcriptJob} />
    </Modal>
  );
};

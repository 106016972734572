import { useTranscriptJob } from "src/shared/context/TranscriptJobProvider";
import { JobMetadata } from "./JobMetadata";

export const JobCustomer = () => {
  const { transcriptJob } = useTranscriptJob();

  return (
    <JobMetadata
      label="Customer"
      value={`${transcriptJob?.customerUser?.firstName} ${transcriptJob?.customerUser?.lastName} (#${transcriptJob?.customerUserId})`}
    />
  );
};

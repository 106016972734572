import { useTranscriptJob } from "src/shared/context/TranscriptJobProvider"
import { JobMetadata } from "./JobMetadata"

export const JobCreatedOn = () => {
  const { transcriptJob } = useTranscriptJob()

  if (!transcriptJob) {
    return null
  }

  return (
    <JobMetadata
      label="Created on"
      value={new Date(transcriptJob?.createdOn).toLocaleString()}
    />
  )
}
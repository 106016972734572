import { useModal } from "src/shared/context/ModalProvider";
import Modal from "../Modal";
import { AudioRating } from "../AudioRating.tsx";
import { TranscriptJob } from "src/shared/api";

interface ModalCompleteJobProps {
  completeJob: () => Promise<void>;
  transcriptJob: TranscriptJob;
}

export const ModalCompleteJob = ({
  completeJob,
  transcriptJob,
}: ModalCompleteJobProps) => {
  const { closeModal } = useModal();
  return (
    <Modal
      title="Ready to complete job?"
      dialogue={{
        primary: {
          onClick: () => {
            completeJob();
            closeModal();
          },
          title: "Complete Job",
          autoFocus: true,
        },
        secondary: { onClick: closeModal },
      }}
    >
      <AudioRating transcriptJob={transcriptJob} />
    </Modal>
  );
};

import { API } from "aws-amplify";
import { EventKind } from "./events";

type PaginatedResponse<T> = {
  data: T[];
  totalCount: number;
  hasMore: boolean;
};

enum TranscriptJobStatus {
  Pending = "pending",
  InProgress = "in_progress",
  InReview = "in_review",
  Completed = "completed",
  Canceled = "canceled",
}

type Audio = {
  id: number;
  url: string | null;
  audioLengthInSeconds: number | null;
};

type CustomerUser = {
  id: number;
  firstName: string;
  lastName: string;
  internalNotes: string | null;
  companyWebsite: string | null;
  emails: {
    userId: number;
    name: string;
    emailAddress: string;
    isDefault: number;
    isPrimary: number;
  }[];
};

type TranscriptJob = {
  id: number;
  status: TranscriptJobStatus;
  priority: number;
  assignedEmployeeId: number | null;
  rewardedEmployeeId: number | null;
  audioId: number;
  audio: Audio | null;
  customerUserId: number;
  customerUser: CustomerUser | null;
  comments: string | null;
  createdOn: string;
  updatedOn: string;
  assignedEmployee: Employee | null;
  firstName: string;
  lastName: string;
  audioLengthInSeconds: number;
  recipientEmails: string[] | null;
  transcriptionStart: string | null;
  transcriptionEnd: string | null;
};

type TranscriptReviewJob = {
  id: number;
  status: TranscriptJobStatus;
  priority: number;
  assignedEmployeeId: number | null;
  rewardedEmployeeId: number | null;
  audioId: number;
  audio: Audio | null;
  customerUserId: number;
  customerUser: CustomerUser | null;
  comments: string | null;
  createdOn: string;
  updatedOn: string;
  assignedEmployee: Employee | null;
  createdByEmployee: Employee | null;
  firstName: string;
  lastName: string;
  audioLengthInSeconds: number;
  recipientEmails: string[] | null;
  transcriptionStart: string | null;
  transcriptionEnd: string | null;
};

export type TextSegment = {
  word: string;
  start: number;
  end: number;
  confidence: number;
  punctuated_word: string;
};

type TranscriptVersion = {
  id: number;
  headline: string;
  text: string;
  textWithTimestamps: string | TextSegment[] | null | undefined;
  transcriptJobId: number;
  metadataJson: string | null;
  createdByEmployeeId: number | null;
  createdByTranscriptEngineId: number | null;
  createdOn: string;
  updatedOn: string;
  versionStatus: string;
};

export enum EmployeeRole {
  Admin = "admin",
  Editor = "editor",
  Transcriber = "transcriptionist",
}

export type Employee = {
  id: number;
  avatarUri: string | null;
  role: EmployeeRole;
  cognitoId: string | null;
  firstName: string | null;
  lastName: string | null;
  dailyLineCountGoal: number | null;
  dailyLineCount: number | null;
  createdOn: string;
  updatedOn: string;
};

export type JobBacklogInsights = {
  jobBacklogCount: number;
  jobBacklogAudioMinutes: number;
  jobBacklogAverageAge: number;
  jobBacklogOldestAge: number;
};

export type MyJobBacklogInsights = {
  jobBacklogCount: number;
  jobBacklogAudioMinutes: number;
  jobsOwnedCount: number;
  jobsOwnedAudioMinutes: number;
};

type Event = {
  id: string
  event: EventKind,
  actorId: string,
  actor: string,
  properties: object,
  createdOn: string
}

const mapSnakeToCamel = (obj: any) => {
  if (obj === null || obj === undefined) {
    return obj;
  }
  if (Array.isArray(obj)) {
    return obj.map(mapSnakeToCamel);
  }
  if (typeof obj === "object") {
    return Object.keys(obj).reduce((acc, key) => {
      const camelKey = key.replace(/([-_][a-z])/gi, ($1) => {
        return $1.toUpperCase().replace("-", "").replace("_", "");
      });
      return {
        ...acc,
        [camelKey]: mapSnakeToCamel(obj[key]),
      };
    }, {});
  }
  return obj;
};

const mapCamelToSnake = (obj: any) => {
  if (obj === null || obj === undefined) {
    return obj;
  }
  if (Array.isArray(obj)) {
    return obj.map(mapCamelToSnake);
  }
  if (typeof obj === "object") {
    return Object.keys(obj).reduce((acc, key) => {
      const snakeKey = key.replace(/([A-Z])/g, ($1) => {
        return "_" + $1.toLowerCase();
      });
      return {
        ...acc,
        [snakeKey]: mapCamelToSnake(obj[key]),
      };
    }, {});
  }
  return obj;
};

export const getEmployee = async (): Promise<Employee> => {
  const response = await API.get("SCRIBE_API", "/employees/me", {});
  return mapSnakeToCamel(response);
};

type ListTranscriptJobsInput = {
  afterId?: number;
  status?: TranscriptJobStatus;
  assignedEmployeeId?: number;
  limit?: number;
  notStatus?: TranscriptJobStatus;
};

const listTranscriptJobs = async ({
  afterId,
  status,
  assignedEmployeeId,
  limit,
  notStatus,
}: ListTranscriptJobsInput | undefined = {}): Promise<
  PaginatedResponse<TranscriptJob>
> => {
  const response = await API.get("SCRIBE_API", "/transcript_jobs", {
    queryStringParameters: mapCamelToSnake({
      afterId,
      status,
      assignedEmployeeId,
      limit: limit ?? 10,
      notStatus,
    }),
  });
  return mapSnakeToCamel(response);
};

const listReviewJobs = async ({
  afterId,
  status,
  assignedEmployeeId,
  limit,
  notStatus,
}: ListTranscriptJobsInput | undefined = {}): Promise<
  PaginatedResponse<TranscriptReviewJob>
> => {
  const response = await API.get("SCRIBE_API", "/transcript_jobs_review", {
    queryStringParameters: mapCamelToSnake({
      afterId,
      status,
      assignedEmployeeId,
      limit: limit ?? 10,
      notStatus,
    }),
  });
  return mapSnakeToCamel(response);
};

type ListEmployeesInput = {
  afterId?: number;
  limit?: number;
};

export const listEmployees = async ({
  afterId,
  limit,
}: ListEmployeesInput | undefined = {}): Promise<
  PaginatedResponse<Employee>
> => {
  const response = await API.get("SCRIBE_API", "/employees", {
    queryStringParameters: mapCamelToSnake({
      afterId,
      limit: limit ?? 10,
    }),
  });
  return mapSnakeToCamel(response);
};

const claimNextTranscriptJob = async (
  employeeId: number
): Promise<TranscriptJob> => {
  const response = await API.post("SCRIBE_API", "/transcript_jobs/claim_next", {
    body: mapCamelToSnake({ assignedEmployeeId: employeeId }),
  });
  return mapSnakeToCamel(response);
};

export const rateAudioQuality = async (
  id: number,
  rating: number
): Promise<TranscriptJob> => {
  const response = await API.post(
    "SCRIBE_API",
    `/transcript_jobs/${id}/rate_audio_quality`,
    {
      body: mapCamelToSnake({ rating }),
    }
  );
  return mapSnakeToCamel(response);
};

export const claimTranscriptJob = async (
  id: number,
  employeeId: number,
  status?: TranscriptJobStatus
): Promise<TranscriptJob> => {
  const response = await API.post(
    "SCRIBE_API",
    `/transcript_jobs/${id}/assign`,
    {
      body: mapCamelToSnake({ assignedEmployeeId: employeeId, status }),
    }
  );
  return mapSnakeToCamel(response);
};

export const createTranscriptVersion = async (
  id: number,
  transcriptVersion: Partial<TranscriptVersion>
): Promise<TranscriptVersion> => {
  const response = await API.post(
    "SCRIBE_API",
    `/transcript_jobs/${id}/transcript_versions`,
    {
      body: mapCamelToSnake(transcriptVersion),
    }
  );
  return mapSnakeToCamel(response);
};

export const updateTranscriptVersion = async (
  id: number,
  versionId: number,
  transcriptVersion: Partial<TranscriptVersion>
): Promise<TranscriptVersion> => {
  const response = await API.put(
    "SCRIBE_API",
    `/transcript_jobs/${id}/transcript_versions/${versionId}`,
    {
      body: mapCamelToSnake(transcriptVersion),
    }
  );
  return mapSnakeToCamel(response);
};

export const deleteTranscriptVersions = async (
  id: number,
): Promise<void> => {
  await API.del("SCRIBE_API", `/transcript_jobs/${id}/transcript_versions`, {});
};

export const updateEmployee = async (
  id: number,
  employee: Partial<Employee>
): Promise<Employee> => {
  const response = await API.put("SCRIBE_API", `/employees/${id}`, {
    body: mapCamelToSnake(employee),
  });
  return mapSnakeToCamel(response);
};

const completeTranscriptJob = async (
  id: number,
  transcriptVersion: Partial<TranscriptVersion>
): Promise<TranscriptJob> => {
  await createTranscriptVersion(id, transcriptVersion);
  const response = await API.put("SCRIBE_API", `/transcript_jobs/${id}`, {
    body: mapCamelToSnake({ status: TranscriptJobStatus.Completed, transcriptionEnd: new Date().toISOString() }),
  });
  return mapSnakeToCamel(response);
};

export const sendTranscriptJobToQa = async (
  id: number,
  transcriptVersion: Partial<TranscriptVersion>,
  recipientEmails: string[] | null,
  comments?: string,
): Promise<TranscriptJob> => {
  await createTranscriptVersion(id, transcriptVersion);
  const response = await API.put("SCRIBE_API", `/transcript_jobs/${id}`, {
    body: mapCamelToSnake({
      status: TranscriptJobStatus.InReview,
      assignedEmployeeId: null,
      transcriptionEnd: new Date().toISOString(),
      comments,
      recipientEmails
    }),
  });
  return mapSnakeToCamel(response);
};

export const abandonTranscriptJob = async (
  transcriptJob: TranscriptJob,
  nextStatus: TranscriptJobStatus
): Promise<TranscriptJob> => {
  const isInProgress = transcriptJob.status === TranscriptJobStatus.InProgress;
  nextStatus !== TranscriptJobStatus.InReview && await deleteTranscriptVersions(transcriptJob.id);
  const response = await API.put(
    "SCRIBE_API",
    `/transcript_jobs/${transcriptJob.id}`,
    {
      body: mapCamelToSnake({
        status: nextStatus,
        assignedEmployeeId: null,
        rewardedEmployeeId: isInProgress
          ? null
          : transcriptJob.rewardedEmployeeId,
        recipientEmails: nextStatus === TranscriptJobStatus.InReview
          ? transcriptJob.recipientEmails
          : null
      }),
    }
  );
  return mapSnakeToCamel(response);
};

export const updateTranscriptJob = async (
  id: number,
  transcriptJob: Partial<TranscriptJob>
): Promise<TranscriptJob> => {
  const response = await API.put("SCRIBE_API", `/transcript_jobs/${id}`, {
    body: mapCamelToSnake(transcriptJob),
  });
  return mapSnakeToCamel(response);
};

const getTranscriptJob = async (id: number): Promise<TranscriptJob> => {
  const response = await API.get("SCRIBE_API", `/transcript_jobs/${id}`, {});
  return mapSnakeToCamel(response);
};

const getTranscriptJobLatestTranscript = async (
  id: number
): Promise<PaginatedResponse<TranscriptVersion>> => {
  const response = await API.get(
    "SCRIBE_API",
    `/transcript_jobs/${id}/transcript_versions?sequence=latest`,
    {}
  );
  return mapSnakeToCamel(response);
};

export const getJobBacklogInsights = async (): Promise<JobBacklogInsights> => {
  const response = await API.get("SCRIBE_API", `/insights/jobs_backlog`, {});
  return mapSnakeToCamel(response);
};

export const getMyJobBacklogInsights = async (
  id: number
): Promise<MyJobBacklogInsights> => {
  const response = await API.get(
    "SCRIBE_API",
    `/insights/my_jobs_backlog/${id}`,
    {}
  );
  return mapSnakeToCamel(response);
};

export const updateUserNotes = async (
  id: number,
  internalNotes: string | null
): Promise<{ internalNotes: string | null }> => {
  const response = await API.put("SCRIBE_API", `/users/${id}`, {
    body: mapCamelToSnake({ internalNotes }),
  });
  return mapSnakeToCamel(response);
};

export const createLogEvent = async (event: Partial<Event>): Promise<void> => {
  try {
    await API.post('SCRIBE_API', `/events`, { body: mapCamelToSnake(event) })
  } catch (error) {
    console.error(error)
  }
}

export {
  claimNextTranscriptJob,
  completeTranscriptJob,
  getTranscriptJobLatestTranscript,
  getTranscriptJob,
  listTranscriptJobs,
  listReviewJobs,
  type PaginatedResponse,
  type TranscriptJob,
  type TranscriptReviewJob,
  type TranscriptVersion,
  TranscriptJobStatus,
};

import { Flex, Kbd, Text } from "@chakra-ui/react";
import { useState } from "react";
import { Rating } from "react-simple-star-rating";
import { TranscriptJob, rateAudioQuality } from "src/shared/api";

interface AudioRatingProps {
  transcriptJob: TranscriptJob;
}

export const AudioRating = ({ transcriptJob }: AudioRatingProps) => {
  const [rating, setRating] = useState<number>(0);

  const saveAudioQualityRating = (value: number) => {
    setRating(value);
    try {
      rateAudioQuality(transcriptJob.id, value);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Flex
      borderWidth="1px"
      borderRadius="md"
      p="4"
      py="8"
      flexDirection="column"
      alignItems="center"
    >
      <Text fontSize="sm" mb="1">
        Rate the audio quality (type <Kbd>1-5</Kbd>)
      </Text>
      <Rating
        initialValue={rating}
        onClick={saveAudioQualityRating}
        transition
        SVGstyle={{ display: "inline-block" }}
      />
    </Flex>
  );
};

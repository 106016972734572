import { JSONContent } from "@tiptap/react";
import { TextSegment, TranscriptVersion } from "src/shared/api";

const LOW_CONFIDENCE_THRESHOLD = 0.9;

export const parseTextSegments = (
  textWithTimestamps: TranscriptVersion["textWithTimestamps"]
): TextSegment[] | null => {
  try {
    return typeof textWithTimestamps === "string"
      ? (JSON.parse(textWithTimestamps) as TextSegment[])
      : null;
  } catch (e) {
    return null;
  }
};

export const makeTextContent = (content: JSONContent | null): string => {
  return (
    content?.content
      ?.map((paragraph) => {
        return paragraph.content
          ?.map((text) => {
            return text.text;
          })
          .join("");
      })
      .join("\n") ?? ""
  ).trim();
};

export const makeJsonContent = (
  text: string | null | undefined,
  textWithTimestamps: string | TextSegment[] | null | undefined
): JSONContent | null => {
  const segments = parseTextSegments(textWithTimestamps);

  if ((segments?.length ?? 0) > 0) {
    return {
      type: "doc",
      content: [
        {
          type: "paragraph",
          content: segments?.map((segment) => ({
            type: "text",
            text: `${segment.punctuated_word} `,
            marks:
              segment.confidence < LOW_CONFIDENCE_THRESHOLD
                ? [{ type: "highlight" }]
                : [],
          })),
        },
      ],
    };
  }

  if (typeof text === "string" && text.length > 0) {
    const paragraphs = text.split("\n");
    const makeWords = (paragraph: string) => paragraph.trim().split(" ");

    return {
      type: "doc",
      content: paragraphs.map((paragraph) => ({
        type: "paragraph",
        content: makeWords(paragraph).map((word) => {
          return {
            type: "text",
            text: `${word} `,
            marks: word === "??" ? [{ type: "highlight" }] : [],
          };
        }),
      })),
    };
  }

  return null;
};

export const makeLowConfidenceTextSegments = (
  textWithTimestamps: TranscriptVersion["textWithTimestamps"]
): TextSegment[] => {
  const segments = parseTextSegments(textWithTimestamps);
  return (
    segments?.filter(
      (segment) => segment.confidence < LOW_CONFIDENCE_THRESHOLD
    ) ?? []
  );
};

export const isRapidModeAvailable = (
  textWithTimestamps: TranscriptVersion["textWithTimestamps"]
) => {
  return (parseTextSegments(textWithTimestamps)?.length ?? 0) > 0;
};

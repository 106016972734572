import { useAuth } from "src/shared/context/AuthProvider";
import { StatusBadge } from "../../StatusBadge";
import { JobMetadata } from "./JobMetadata";
import { useTranscriptJob } from "src/shared/context/TranscriptJobProvider";
import { isAdmin } from "src/shared/utils";

export const JobStatus = () => {
  const { currentEmployee } = useAuth();
  const { transcriptJob } = useTranscriptJob();

  if (!transcriptJob || !isAdmin(currentEmployee)) {
    return null;
  }

  return (
    <JobMetadata
      label="Status"
      value={<StatusBadge status={transcriptJob?.status} transcriptJob={transcriptJob} size="6" />}
    />
  );
};

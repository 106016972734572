import {
  Box,
  CircularProgress,
  Flex,
  HStack,
  Stack,
  Text,
} from "@chakra-ui/react";
import { Employee } from "src/shared/api";
import { Button } from "../Button";
import { H3 } from "../Text";
import { useModal } from "src/shared/context/ModalProvider";
import { ModalUpdateDailyGoal } from "../ModalUpdateDailyGoal";

export const DailyGoal = ({
  employee,
  vertical,
  onSave,
}: {
  employee?: Employee | null;
  vertical?: boolean;
  onSave?: () => Promise<void> | void;
}) => {
  const { openModal } = useModal();
  const dailyLineCountAsNumber = employee?.dailyLineCount || 0;
  const dailyLineCountGoalAsNumber = employee?.dailyLineCountGoal || 0;
  const progress = (dailyLineCountAsNumber / dailyLineCountGoalAsNumber) * 100;
  const dailyGoal =
    dailyLineCountGoalAsNumber > 0 && `/${dailyLineCountGoalAsNumber}`;

  const openUpdateDailyGoalModal = () => {
    openModal(<ModalUpdateDailyGoal employee={employee!} onSave={onSave} />);
  };

  if (vertical) {
    return (
      <Box
        border="2px solid #E5E6E8"
        padding="1.5rem"
        borderRadius="10px"
        minW="250px"
      >
        <HStack justifyContent="space-between">
          <H3>Today</H3>
          <Button
            secondary
            size="xs"
            mt="-2"
            onClick={openUpdateDailyGoalModal}
          >
            Change Goal
          </Button>
        </HStack>

        <Stack
          justifyContent="center"
          alignItems="center"
          height="100%"
          mt="-2"
          position="relative"
        >
          <CircularProgress size="13rem" value={progress} color="#4DB1F0" />
          <Stack position="absolute" textAlign="center" gap="0" mt="-1">
            <Text fontSize="xl">
              {dailyLineCountAsNumber}
              {dailyGoal}
            </Text>
            <Text opacity="0.5">Lines complete</Text>
          </Stack>
        </Stack>
      </Box>
    );
  }

  return (
    <Flex
      display="inline-flex"
      alignItems="center"
      gap="1"
      onClick={openUpdateDailyGoalModal}
      cursor="pointer"
      transition="background-color 0.2s ease"
      borderRadius="md"
      padding="0.25rem"
      margin="-0.25rem"
      _hover={{ bg: "#ccc" }}
      userSelect="none"
    >
      <CircularProgress
        size="4"
        thickness="20px"
        value={progress}
        color="#4DB1F0"
      />
      {dailyLineCountAsNumber}
      {dailyGoal} lines
    </Flex>
  );
};

import { useEffect, useRef, useState } from "react";
import {
  MdCancel,
  MdCheckCircle,
  MdEdit,
  MdExpandMore,
  MdSend,
  MdVisibility,
} from "react-icons/md";
import { TranscriptJobStatus, claimTranscriptJob } from "src/shared/api";
import { Button } from "src/shared/components/Button";
import { useTranscriptJob } from "src/shared/context/TranscriptJobProvider";
import { useModal } from "src/shared/context/ModalProvider";
import { ModalEditorSettings } from "../ModalEditorSettings";
import { Action, useShortcut } from "src/shared/shortcuts";
import { isRapidModeAvailable } from "./utils";
import {
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Switch,
  Text,
} from "@chakra-ui/react";
import { useAuth } from "src/shared/context/AuthProvider";
import { isAdmin } from "src/shared/utils";
import { toast } from "sonner";

const ModeMenu = () => {
  const { inProgress } = useTranscriptJob();

  return (
    <>
      <div
        style={{
          marginRight: "-0.5rem",
          fontSize: "1rem",
          display: "flex",
          alignItems: "center",
          gap: "0.5rem",
          border: "1px solid #DDD",
          padding: "0.3rem 1rem",
          paddingLeft: "0.75rem",
          borderRadius: "10px",
          userSelect: "none",
          height: "32px",
        }}
      >
        {inProgress ? (
          <>
            <MdEdit />
            Editing
          </>
        ) : (
          <>
            <MdVisibility />
            Viewing
          </>
        )}
      </div>
    </>
  );
};

const CompleteMenu = ({ disabled }) => {
  const { completeJob, abandonJob, sendJobToQa } = useTranscriptJob();

  return (
    <Menu placement="bottom-end" offset={[0, 4]}>
      <MenuButton
        disabled={disabled}
        as={Button}
        {...{
          borderRadius: "0 10px 10px 0",
          borderLeft: "1px solid #dee2e6",
          padding: "0.3rem",
          paddingTop: "0.5rem",
          height: "32px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Icon as={MdExpandMore} color="white" />
      </MenuButton>
      <MenuList
        shadow="lg"
        borderRadius="lg"
      >
        <MenuItem
          onClick={completeJob}
          icon={<MdCheckCircle fontSize="1.25rem" />}
        >
          <Text>Complete job</Text>
        </MenuItem>
        <MenuItem onClick={abandonJob} icon={<MdCancel fontSize="1.25rem" />}>
          <Text>Abandon job</Text>
        </MenuItem>
        <MenuItem onClick={sendJobToQa} icon={<MdSend fontSize="1.25rem" />}>
          <Text>Send to QA</Text>
        </MenuItem>
      </MenuList>
    </Menu>
  );
};

const PrimaryJobAction = () => {
  const { currentEmployee } = useAuth();
  const {
    completeJob,
    transcriptJob,
    completeJobLoading,
    abandonJobLoading,
    inProgress,
    reloadJob,
  } = useTranscriptJob();
  const [claimJobLoading, setClaimJobLoading] = useState(false);

  const loading = completeJobLoading || abandonJobLoading;

  if (!transcriptJob || !currentEmployee) {
    return null;
  }

  const getNextStatus = () => {
    if (transcriptJob.status === TranscriptJobStatus.Pending) {
      return TranscriptJobStatus.InProgress;
    }
    if (transcriptJob.status === TranscriptJobStatus.InReview) {
      return TranscriptJobStatus.InReview;
    }
    return transcriptJob.status;
  };

  if (
    (!inProgress && !isAdmin(currentEmployee)) ||
    transcriptJob.status === TranscriptJobStatus.Completed
  ) {
    return null;
  }

  if (!inProgress) {
    return (
      <Button
        onClick={async () => {
          try {
            setClaimJobLoading(true);
            await claimTranscriptJob(
              transcriptJob?.id,
              currentEmployee?.id,
              getNextStatus()
            );
            reloadJob();
          } catch (e) {
            toast.error("Failed to claim job, likely already claimed");
            console.error(e);
          } finally {
            setClaimJobLoading(false);
          }
        }}
        disabled={loading}
        loading={claimJobLoading}
      >
        Claim Job
      </Button>
    );
  }

  return (
    <div className="multi-button">
      <Button
        onClick={completeJob}
        style={{ width: "fit-content" }}
        borderRadius="10px 0 0 10px"
        height="32px"
        disabled={loading}
        loading={loading}
      >
        Complete
      </Button>
      <CompleteMenu disabled={loading} />
    </div>
  );
};

const RapidModeSwitch = () => {
  const { isRapidMode, setIsRapidMode } = useTranscriptJob();

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        fontSize: "0.9rem",
        gap: "0.25rem",
      }}
    >
      <div style={{ opacity: 0.5 }}>Rapid</div>
      <Switch
        size="sm"
        isChecked={isRapidMode}
        onChange={() => setIsRapidMode(!isRapidMode)}
      />
    </div>
  );
};

const ShortcutsMenu = () => {
  const { openModal, modal, closeModal } = useModal();

  const toggleShortcuts = () => {
    if (modal) {
      closeModal();
    } else {
      openModal(<ModalEditorSettings />);
    }
  };

  useShortcut(Action.ToggleShortcutsMenu, toggleShortcuts);

  return (
    <Button
      secondary
      aria-label="settings"
      style={{ marginRight: "-0.5rem" }}
      onClick={toggleShortcuts}
    >
      Shortcuts
    </Button>
  );
};

export const EditorHeader = () => {
  const { transcriptJob, transcriptVersion } = useTranscriptJob();

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
        top: 0,
        left: 0,
        padding: "0.9rem 1rem",
        background: "white",
        zIndex: 1000,
        borderBottom: "1px solid #ddd",
      }}
      className="editor-header"
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <EditorTitle />
        <Text
          style={{
            marginLeft: "0.5rem",
            opacity: 0.6,
            fontSize: "1rem",
          }}
          noOfLines={1}
        >
          Job #{transcriptJob?.id}
        </Text>
      </div>

      <div style={{ display: "flex", gap: "1rem", alignItems: "center" }}>
        {isRapidModeAvailable(transcriptVersion?.textWithTimestamps) && (
          <RapidModeSwitch />
        )}
        <ModeMenu />
        <ShortcutsMenu />
        <PrimaryJobAction />
      </div>
    </div>
  );
};

const EditorTitle = () => {
  const { title, setTitle, inProgress } = useTranscriptJob();
  const titleWidthRef = useRef(null);
  const titleRef = useRef(null);
  const initialTitleWidth = 128;
  const [titleWidth, setTitleWidth] = useState(initialTitleWidth);

  useShortcut(Action.JumpToSubjectLine, () => {
    (titleRef as any)?.current?.focus();
  });

  useEffect(() => {
    if (title.length === 0) {
      setTitleWidth(initialTitleWidth);
    } else {
      setTitleWidth((titleWidthRef as any)?.current?.offsetWidth || 0);
    }
  }, [title]);

  return (
    <>
      <div
        className="transcript-title"
        style={{
          visibility: "hidden",
          pointerEvents: "none",
          position: "absolute",
          whiteSpace: "pre",
        }}
        ref={titleWidthRef}
      >
        {title}
      </div>
      <input
        className="transcript-title"
        ref={titleRef}
        value={title}
        style={{ width: `${titleWidth}px`, height: "32px" }}
        placeholder="Subject Line"
        onChange={(e) => setTitle(e.target.value ?? "")}
        disabled={!inProgress}
      />
    </>
  );
};

import React, { useState } from 'react';
import { Switch, Text, Button, Flex, Box } from "@chakra-ui/react";
import { JobMetadata } from "./JobMetadata";
import { useTranscriptJob } from "src/shared/context/TranscriptJobProvider";
import { ModalSelectEmailRecipients } from "../../ModalSelectEmailRecipients";
import { TranscriptJob } from 'src/shared/api';
import { useModal } from "src/shared/context/ModalProvider";

const JobTypeSelector = ({ transcriptJob }: { transcriptJob: TranscriptJob }) => {
  const [isEmail, setIsEmail] = useState(
    transcriptJob.recipientEmails && transcriptJob.recipientEmails.length > 0
  );
  const { openModal } = useModal();
  const { reloadJob } = useTranscriptJob();

  const handleEmailClick = () => {
    openModal(
      <ModalSelectEmailRecipients 
        transcriptJob={transcriptJob}
        emails={(transcriptJob.customerUser?.emails || []).map(email => ({
          userId: email.userId,
          name: email.name,
          emailAddress: email.emailAddress,
          isDefault: email.isDefault,
          isPrimary: email.isPrimary,
        }))}
        onSave={reloadJob}
      />
    );
  };

  return (
    <Flex alignItems="center" justifyContent="space-between">
      <Flex alignItems="center">
        <Text mr={2}>Note</Text>
        <Switch
          isChecked={isEmail ?? false}
          onChange={() => setIsEmail(!isEmail)}
          mr={2}
        />
        <Text>Email</Text>
      </Flex>
      {isEmail && (
        <Box ml={4}>
          <Button size="sm" onClick={handleEmailClick}>Select Email Recipients</Button>
        </Box>
      )}
    </Flex>
  );
};

export const JobType = () => {
  const { transcriptJob } = useTranscriptJob();

  if (!transcriptJob) {
    return null;
  }

  return (
    <JobMetadata
      label="Job Type"
      value={<JobTypeSelector transcriptJob={transcriptJob} />}
    />
  );
};

import { useEffect, useState } from "react";
import {
  JobBacklogInsights,
  MyJobBacklogInsights,
  getJobBacklogInsights,
  getMyJobBacklogInsights,
} from "src/shared/api";
import { ClaimNextTranscriptJob } from "../jobs/JobDetail";
import { PageLayout } from "src/shared/components/PageLayout";
import { H3 } from "src/shared/components/Text";
import { useInterval } from "usehooks-ts";
import LoadingSpinner from "src/shared/components/LoadingSpinner";
import { useAuth } from "src/shared/context/AuthProvider";
import {
  Box,
  HStack,
  Stat,
  StatGroup,
  StatLabel,
  StatNumber,
} from "@chakra-ui/react";
import { DailyGoal } from "src/shared/components/DailyGoal";
import { formatTimeCondensed } from "src/shared/formatters";
import { isAdmin } from "src/shared/utils";

export const Home = () => {
  const { currentEmployee } = useAuth();
  const [loading, setLoading] = useState(true);
  const [lastFetch, setLastFetch] = useState<Date | null>(null);
  const [backlogInsights, setBacklogInsights] = useState<JobBacklogInsights | null>(null);
  const [myBacklogInsights, setMyBacklogInsights] = useState<MyJobBacklogInsights | null>(null);

  const timeOfDayGreeting = () => {
    const date = new Date();
    const hours = date.getHours();
    if (hours < 12) {
      return "Good morning";
    } else if (hours < 18) {
      return "Good afternoon";
    } else {
      return "Good evening";
    }
  };

  useEffect(() => {
    (async () => {
      if (!currentEmployee) return;
      if (isAdmin(currentEmployee)) {
        const backlogInsightsResponse = await getJobBacklogInsights();
        setBacklogInsights(backlogInsightsResponse);
      }
      const myBacklogInsightsResponse = await getMyJobBacklogInsights(currentEmployee?.id);
      setMyBacklogInsights(myBacklogInsightsResponse);
      setLoading(false);
      setLastFetch(new Date());
    })();

    // Only run this effect once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useInterval(async () => {
    if (!currentEmployee) return;
    if (isAdmin(currentEmployee)) {
      const backlogInsightsResponse = await getJobBacklogInsights();
      setBacklogInsights(backlogInsightsResponse);
    }
    const myBacklogInsightsResponse = await getMyJobBacklogInsights(currentEmployee?.id);
    setMyBacklogInsights(myBacklogInsightsResponse);
    setLastFetch(new Date());
  }, 10000);

  const isNextJobDisabled = myBacklogInsights?.jobBacklogCount === 0;

  const greeting = `${timeOfDayGreeting()}${
    currentEmployee?.firstName ? `, ${currentEmployee?.firstName}` : ""
  }`;

  if (loading && !lastFetch) {
    return (
      <PageLayout title={greeting}>
        <LoadingSpinner />
      </PageLayout>
    );
  }

  return (
    <PageLayout title={greeting}>
      {backlogInsights && (
        <Box
          border="2px solid #E5E6E8"
          padding="1.5rem"
          borderRadius="10px"
          flex="1"
          mb="4"
        >
          <H3>Job Backlog</H3>
          <StatGroup mt="4">
            <Stat>
              <StatLabel># of Jobs</StatLabel>
              <StatNumber>{backlogInsights?.jobBacklogCount}</StatNumber>
            </Stat>

            <Stat>
              <StatLabel>Audio minutes</StatLabel>
              <StatNumber>{backlogInsights?.jobBacklogAudioMinutes}</StatNumber>
            </Stat>

            <Stat>
              <StatLabel>Average age</StatLabel>
              <StatNumber>
                {formatTimeCondensed(
                  backlogInsights?.jobBacklogAverageAge ?? 0
                )}
              </StatNumber>
            </Stat>

            <Stat>
              <StatLabel>Oldest Job</StatLabel>
              <StatNumber>
                {formatTimeCondensed(backlogInsights?.jobBacklogOldestAge ?? 0)}
              </StatNumber>
            </Stat>
          </StatGroup>
        </Box>
      )}
      <HStack width="100%" alignItems="normal" gap="4">
        <DailyGoal vertical employee={currentEmployee} />
        <Box
          border="2px solid #E5E6E8"
          padding="1.5rem"
          borderRadius="10px"
          flex="1"
        >
          <H3>Current Jobs</H3>
          {(myBacklogInsights?.jobsOwnedCount ?? 0) === 0 ? (
            <p style={{ marginBottom: "1.5rem" }}>
              You don’t have any jobs in progress. Ready to start your next job?
            </p>
          ) : (
            <p style={{ marginBottom: "1.5rem" }}>
              You have {myBacklogInsights?.jobsOwnedCount} jobs assigned to you. Ready to
              resume?
            </p>
          )}
          <ClaimNextTranscriptJob
            disabled={isNextJobDisabled}
            label={(myBacklogInsights?.jobsOwnedCount ?? 0) > 0 ? "Resume Job" : ""}
          />
          <div style={{ opacity: 0.6, marginTop: "1rem" }}>
            {(myBacklogInsights?.jobBacklogCount ?? 0) > 0 ? (
              <p>{myBacklogInsights?.jobBacklogCount} jobs ({myBacklogInsights?.jobBacklogAudioMinutes} audio minutes) in backlog</p>
            ) : (
              <p>No jobs in the backlog</p>
            )}
          </div>
        </Box>
      </HStack>
    </PageLayout>
  );
};

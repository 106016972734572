import { Avatar, Box, Flex, Text } from "@chakra-ui/react";
import { Employee, TranscriptJob } from "src/shared/api";
import { stringToColor } from "src/shared/utils";
import { useModal } from "src/shared/context/ModalProvider";
import { ModalUpdateJobAssignee } from "src/shared/components/ModalUpdateJobAssignee";
import { useAuth } from "src/shared/context/AuthProvider";
import { isAdmin } from "src/shared/utils";

export const AssigneeBadge = ({
  employee,
  transcriptJob,
  readOnly,
  onSave,
}: {
  employee: Employee | null;
  transcriptJob: TranscriptJob;
  readOnly?: boolean;
  onSave?: () => Promise<void> | void;
}) => {
  const { currentEmployee } = useAuth();
  const { openModal } = useModal();

  const admin = isAdmin(currentEmployee);
  
  const hoverProps = admin && !readOnly ? { _hover: { bg: "#ccc", cursor: "pointer" } } : {};

  const openUpdateAssigneeModal = () => {
    if (!admin || readOnly) return;
    openModal(<ModalUpdateJobAssignee transcriptJob={transcriptJob} onSave={onSave} />);
  };

  if (!employee) {
    return (
      <Flex 
        alignItems="center" 
        gap="1" 
        opacity={0.5}
        onClick={openUpdateAssigneeModal}
        transition="background-color 0.2s ease"
        borderRadius="md"
        padding="0.25rem"
        margin="-0.25rem"
        userSelect="none"
        {...hoverProps}
      >
        <Box boxSize="6" border="2px dashed #ccc" borderRadius="50%" />
        <Text>Unassigned</Text>
      </Flex>
    );
  }

  const fullName = `${employee.firstName} ${employee.lastName}`;

  return (
    <Flex 
      alignItems="center" 
      gap="1"
      onClick={openUpdateAssigneeModal}
      transition="background-color 0.2s ease"
      borderRadius="md"
      padding="0.25rem"
      margin="-0.25rem"
      userSelect="none"
      {...hoverProps}
    >
      <Avatar
        size="xs"
        src={employee?.avatarUri ?? ""}
        bg={stringToColor(fullName)}
      />
      <Text>
        {fullName}
      </Text>
    </Flex>
  );
};
import { useModal } from "src/shared/context/ModalProvider";
import Modal from "../Modal";
import { Textarea } from "@chakra-ui/react";
import { AudioRating } from "../AudioRating.tsx";
import { TranscriptJob } from "src/shared/api";
import { useState } from "react";

interface ModalSendJobToQaProps {
  sendJobToQa: (comments?: string, recipientEmails?: string[] | null) => Promise<void>;
  transcriptJob: TranscriptJob;
}

export const ModalSendJobToQa = ({
  sendJobToQa,
  transcriptJob,
}: ModalSendJobToQaProps) => {
  const { closeModal } = useModal();
  const [comments, setComments] = useState<string>("");

  return (
    <Modal
      title="Ready to send job to QA?"
      dialogue={{
        primary: {
          onClick: () => {
            sendJobToQa(comments.trim()); 
            closeModal();
          },
          title: "Send to QA",
          disabled: !comments.trim(),
        },
        secondary: { onClick: closeModal },
      }}
    >
      <AudioRating transcriptJob={transcriptJob} />
      <Textarea
        placeholder="Add a comment to QA (required)"
        minH="138px"
        autoFocus
        value={comments}
        onChange={(e) => setComments(e.target.value)}
      />
    </Modal>
  );
};

import { Flex, HStack } from "@chakra-ui/react";
import { VisibilityState, createColumnHelper } from "@tanstack/react-table";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { Link } from "react-router-dom";
import { toast } from "sonner";
import {
  Employee,
  TranscriptJob,
  TranscriptJobStatus,
  listTranscriptJobs,
} from "src/shared/api";
import { AssigneeBadge } from "src/shared/components/AssigneeBadge";
import { Button } from "src/shared/components/Button";
import { DropdownMenu } from "src/shared/components/DropdownMenu";
import { ModalJumpToJob } from "src/shared/components/ModalJumpToJob";
import {
  PriorityBadge,
  priorityMap,
} from "src/shared/components/PriorityBadge";
import {
  StatusBadge,
  statusLabelMap,
  statusValueMap,
} from "src/shared/components/StatusBadge";
import { Table } from "src/shared/components/Table";
import { SearchProvider } from "src/shared/components/Table/SearchContext";
import { useModal } from "src/shared/context/ModalProvider";
import { downloadAsCsv } from "./utils"

export type TranscriptJobRow = {
  id: string;
  jobId: string | null;
  status: string | null;
  priority: string | null;
  age: string | null;
  userId: string | null;
  firstName: string | null;
  lastName: string | null;
  audioLength: string | null;
  assignee: Employee | null;
  transcriptionStart: string | null;
  transcriptionEnd: string | null;
  transcriptJob: TranscriptJob;
};

export const JobList = () => {
  const { openModal } = useModal();
  const [transcriptJobs, setTranscriptJobs] = useState<TranscriptJob[]>([]);
  const [loading, setLoading] = useState(true);
  const [status, setStatus] = useState<TranscriptJobStatus>(
    TranscriptJobStatus.Pending
  );

  const loadTranscriptJobs = useCallback(async () => {
    try {
      setLoading(true);
      const statusOrNotStatus = status
        ? { status }
        : { notStatus: TranscriptJobStatus.Completed };
      const response = await listTranscriptJobs({
        limit: 100_000,
        ...statusOrNotStatus,
      });
      setTranscriptJobs(response.data || []);
    } catch (e) {
      console.error(e);
      toast.error("Error loading jobs");
    } finally {
      setLoading(false);
    }
  }, [status]);

  useEffect(() => {
    loadTranscriptJobs();
  }, [loadTranscriptJobs]);

  const transcriptJobRows = useMemo<TranscriptJobRow[]>(
    () =>
      transcriptJobs.map(
        (transcriptJob): TranscriptJobRow => ({
          id: transcriptJob.id.toString(),
          jobId: transcriptJob.id.toString(),
          status: transcriptJob.status,
          priority: transcriptJob.priority?.toString(),
          age: new Date(transcriptJob?.createdOn).toLocaleString(),
          assignee: transcriptJob.assignedEmployee,
          userId: transcriptJob.customerUserId.toString(),
          firstName: transcriptJob.firstName,
          lastName: transcriptJob.lastName,
          audioLength: transcriptJob.audioLengthInSeconds.toString(),
          transcriptionStart: transcriptJob?.transcriptionStart ? new Date(transcriptJob.transcriptionStart).toLocaleString() : null,
          transcriptionEnd: transcriptJob?.transcriptionEnd ? new Date(transcriptJob.transcriptionEnd).toLocaleString() : null,
          transcriptJob,
        })
      ),
    [transcriptJobs]
  );

  const presortedTranscriptJobRows = useMemo(() => {
    if (status === TranscriptJobStatus.Completed) {
      return transcriptJobRows.sort((a, b) => {
        return parseInt(b.id) - parseInt(a.id);
      });
    }
    return transcriptJobRows.sort((a, b) => {
      const priorityA = parseInt(a.priority || "1");
      const priorityB = parseInt(b.priority || "1");

      if (priorityA === priorityB) {
        return parseInt(a.id) - parseInt(b.id);
      }
      return priorityB - priorityA;
    });
  }, [transcriptJobRows, status]);

  const columnHelper = createColumnHelper<TranscriptJobRow>();
  const columns = useMemo(
    () => [
      columnHelper.accessor("jobId", {
        header: "Job ID",
        maxSize: 100,
        cell: (cell) => (
          <Link to={`/jobs/${cell.getValue()}`}>
            <Flex alignItems="center" gap="1" fontWeight="bold">
              {cell.getValue()}
            </Flex>
          </Link>
        ),
      }),
      columnHelper.accessor(
        (cell): string | null =>
          `${statusValueMap[cell.status ?? "failed"]} ${
            cell.status ?? "failed"
          }`,
        {
          id: "status",
          header: "Status",
          maxSize: 115,
          cell: (cell) => (
            <StatusBadge 
              status={cell.row.original.status || "pending"}
              transcriptJob={cell.row.original.transcriptJob}
              onSave={loadTranscriptJobs}
            />
          ),
        }
      ),
      columnHelper.accessor(
        (row) => priorityMap[parseInt(row.priority || "1")],
        {
          id: "priority",
          header: "Priority",
          maxSize: 100,
          cell: (cell) => (
            <PriorityBadge
              onSave={loadTranscriptJobs}
              transcriptJob={cell.row.original.transcriptJob}
              priority={parseInt(cell.row.original.priority || "1")}
            />
          ),
        }
      ),
      columnHelper.accessor((row) => row.assignee?.firstName, {
        id: "assignee",
        header: "Assigned to",
        minSize: 200,
        cell: (cell) => (
          <AssigneeBadge 
            onSave={loadTranscriptJobs}
            employee={cell.row.original.assignee} 
            transcriptJob={cell.row.original.transcriptJob}
          />
        ),
      }),
      columnHelper.accessor("age", {
        header: "Created on",
        minSize: 180,
        cell: (cell) => (
          <Flex alignItems="center">
            {cell.getValue()}
          </Flex>
        ),
      }),
      columnHelper.accessor("audioLength", {
        header: "Length",
        maxSize: 85,
        cell: (cell) => (
          <Flex alignItems="center">
            {Math.floor(Number(cell.getValue()) / 60)}:
            {String(Number(cell.getValue()) % 60).padStart(2, '0')}
          </Flex>
        ),
    }),
      columnHelper.accessor("userId", {
        header: "User ID",
        maxSize: 85,
        cell: (cell) => (
          <Flex alignItems="center">
            {cell.getValue()}
          </Flex>
        ),
      }),
      columnHelper.accessor("firstName", {
        header: "First Name",
        minSize: 120,
        cell: (cell) => (
          <Flex alignItems="center">
            {cell.getValue()}
          </Flex>
        ),
      }),
      columnHelper.accessor("lastName", {
        header: "Last Name",
        minSize: 160,
        cell: (cell) => (
          <Flex alignItems="center">
            {cell.getValue()}
          </Flex>
        ),
      }),
      columnHelper.accessor("transcriptionStart", {
        header: "Transcribe Start",
        minSize: 180,
        cell: (cell) => (
          <Flex alignItems="center">
            {cell.getValue()}
          </Flex>
        ),
      }),
      columnHelper.accessor("transcriptionEnd", {
        header: "Transcription Sent",
        minSize: 180,
        cell: (cell) => (
          <Flex alignItems="center">
            {cell.getValue()}
          </Flex>
        ),
      }),
    ],
    [columnHelper, loadTranscriptJobs]
  );

  const initialColumnVisibility: Record<keyof TranscriptJobRow, boolean> &
    VisibilityState = {
    id: false,
    jobId: true,
    status: true,
    priority: true,
    age: true,
    assignee: true,
    audioLength: true,
    userId: true,
    firstName: true,
    lastName: true,
    transcriptionStart: true,
    transcriptionEnd: true,
    transcriptJob: false,
  };

  const makeStatusOptions = () => {
    const options = Object.values(TranscriptJobStatus).map((status) => ({
      label: statusLabelMap[status],
      value: status,
    })).filter((option) => option.value !== TranscriptJobStatus.InReview);
    return options;
  };

  const openModalJumpToJob = () => {
    openModal(<ModalJumpToJob />);
  };

  useHotkeys("alt+f", openModalJumpToJob);
  useHotkeys("alt+r", loadTranscriptJobs);

  return (
    <SearchProvider>
      <Table
        title="Jobs"
        data={presortedTranscriptJobRows}
        loading={loading}
        columns={columns}
        leftActions={
          <DropdownMenu
            options={makeStatusOptions()}
            value={{ label: statusLabelMap[status], value: status }}
            onChange={(option) =>
              setStatus(option.value as TranscriptJobStatus)
            }
          />
        }
        rightActions={
        <HStack>
          <Button onClick={loadTranscriptJobs} secondary size="sm">
            Refresh Jobs
          </Button>
          <Button onClick={() => downloadAsCsv(transcriptJobs)} secondary size="sm">
            Download CSV
          </Button>
          <Button onClick={openModalJumpToJob} size="sm">
            Find Job
          </Button>
          </HStack>
        }
        initialColumnVisibility={initialColumnVisibility}
      />
    </SearchProvider>
  );
};

import {
  CircularProgress,
  Flex,
  Text,
} from "@chakra-ui/react";
import { TranscriptJob, TranscriptJobStatus } from "src/shared/api";
import { ModalUpdateJobStatus } from "../ModalUpdateJobStatus";
import { useAuth } from "src/shared/context/AuthProvider";
import { useModal } from "src/shared/context/ModalProvider";
import { isAdmin } from "src/shared/utils";

export const statusValueMap = {
  [TranscriptJobStatus.InProgress]: 25,
  [TranscriptJobStatus.InReview]: 50,
  [TranscriptJobStatus.Pending]: 0,
  [TranscriptJobStatus.Completed]: 100,
  [TranscriptJobStatus.Canceled]: 0,
};

export const statusLabelMap = {
  [TranscriptJobStatus.InProgress]: "In Progress",
  [TranscriptJobStatus.InReview]: "In Review",
  [TranscriptJobStatus.Pending]: "Pending",
  [TranscriptJobStatus.Completed]: "Completed",
  [TranscriptJobStatus.Canceled]: "Canceled",
};

export const StatusBadge = ({
  transcriptJob,
  status,
  size,
  readOnly,
  onSave,
}: {
  transcriptJob: TranscriptJob;
  status: string;
  size?: string;
  readOnly?: boolean;
  onSave?: () => Promise<void> | void;
}) => {
  const { currentEmployee } = useAuth();
  const { openModal } = useModal()

  const admin = isAdmin(currentEmployee);
  
  const hoverProps = admin && !readOnly ? { _hover: { bg: "#ccc", cursor: "pointer" } } : {};

  const openUpdateStatusModal = () => {
    if (!admin || readOnly) return;
    openModal(<ModalUpdateJobStatus transcriptJob={transcriptJob} onSave={onSave} />)
  } 

  return (
    <Flex 
      alignItems="center"
      gap="1"
      onClick={openUpdateStatusModal}
      transition="background-color 0.2s ease"
      borderRadius="md"
      padding="0.25rem"
      margin="-0.25rem"
      userSelect="none"
      {...hoverProps}
    >
      <CircularProgress
        size={size ?? "4"}
        thickness="20px"
        trackColor="#ccc"
        value={statusValueMap[status]}
        color={status === "completed" ? "green.500" : "blue.500"}
      />
      <Text>{statusLabelMap[status]}</Text>
  </Flex>
  );
};

import { useModal } from "src/shared/context/ModalProvider";
import Modal from "../Modal";
import { Employee, updateEmployee } from "src/shared/api";
import { useAuth } from "src/shared/context/AuthProvider";
import Input from "../Input";
import { useState } from "react";
import { toast } from "sonner";

interface ModalUpdateDailyGoalProps {
  employee: Employee;
  onSave?: () => Promise<void> | void;
}

export const ModalUpdateDailyGoal = ({
  employee,
  onSave,
}: ModalUpdateDailyGoalProps) => {
  const { currentEmployee, refreshUser } = useAuth();
  const { closeModal } = useModal();
  const [loading, setLoading] = useState(false);
  const [nextDailyLineCountGoal, setNextDailyLineCountGoal] = useState(
    employee.dailyLineCountGoal
  );

  const title =
    currentEmployee?.id === employee.id
      ? "Update your Daily Goal"
      : `Update Daily Goal for ${employee.firstName}`;

  const saveEmployee = async () => {
    setLoading(true);
    await updateEmployee(employee.id, {
      dailyLineCountGoal: nextDailyLineCountGoal,
    });
    await onSave?.();
    await refreshUser()
    toast.success("Daily goal updated");
    setLoading(false);
    closeModal();
  }

  return (
    <Modal
      title={title}
      dialogue={{
        primary: {
          onClick: saveEmployee,
          title: "Save",
          autoFocus: true,
          loading,
        },
        secondary: { onClick: closeModal },
      }}
    >
      <Input
        value={nextDailyLineCountGoal}
        type="number"
        onChange={(e) => setNextDailyLineCountGoal(e.target.value)}
      />
    </Modal>
  );
};

import { AvatarContainer, Square, Details } from "./index.styles";
import { H3, P2 } from "./../Text/.";

interface AvatarProps {
  initials?: string;
  fullName?: string;
  email?: string;
  small?: boolean;
}

const Avatar = ({ initials, fullName, email, small, ...rest }: AvatarProps) => {
  return (
    <AvatarContainer>
      <Square small={small} {...rest}>
        <H3>{initials}</H3>
      </Square>
      {small ? (
        <Details>
          <H3>{fullName ?? email}</H3>
        </Details>
      ) : (
        <Details>
          <H3>{fullName}</H3>
          <P2 data-testid="avatarContainerEmail">{email}</P2>
        </Details>
      )}
    </AvatarContainer>
  );
};

export default Avatar;

import styled, { css } from 'styled-components'

export const StyledApp = styled.div`
    display: flex;
    flex-direction: column;
`

export const StyledLayout = styled.div<{ withBanner?: boolean; $center?: boolean }>`
    display: flex;
    height: ${props => props.withBanner ? 'calc(100vh - 3rem)' : '100vh'};

    ${props => props.$center && css`
        justify-content: center;
        align-items: center;
    `}
`

export const StyledPage = styled.div`
    display: block;
    width: 100%;
    flex: 1;
`

import { useAuth } from "src/shared/context/AuthProvider";
import { PriorityBadge } from "../../PriorityBadge";
import { JobMetadata } from "./JobMetadata";
import { useTranscriptJob } from "src/shared/context/TranscriptJobProvider";
import { isAdmin } from "src/shared/utils";

export const JobPriority = () => {
  const { currentEmployee } = useAuth();
  const { transcriptJob, reloadJob } = useTranscriptJob();
  const admin = isAdmin(currentEmployee);

  if (!transcriptJob || (!admin && transcriptJob?.priority < 4)) {
    return null;
  }

  return (
    <JobMetadata
      label="Priority"
      value={
        <PriorityBadge
          transcriptJob={transcriptJob}
          priority={transcriptJob?.priority ?? 0}
          size="6"
          onSave={reloadJob}
        />
      }
    />
  );
};

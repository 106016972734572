import { MdClose } from "react-icons/md";
import { useTranscriptJob } from "src/shared/context/TranscriptJobProvider";
import { JobPriority } from "./Metadata/JobPriority";
import { JobStatus } from "./Metadata/JobStatus";
import { makeTextContent } from "./utils";
import { Alert, AlertDescription, Box, IconButton } from "@chakra-ui/react";
import { JobAssignee } from "./Metadata/JobAssignee";
import { JobContacts } from "./Metadata/JobContacts";
import { JobCustomer } from "./Metadata/JobCustomer";
import { JobType } from "./Metadata/JobType";
import { JobCreatedOn } from "./Metadata/JobCreatedOn";
import { JobCustomerNotes } from "./Metadata/JobCustomerNotes";
import { JobCustomerWebsite } from "./Metadata/JobCustomerWebsite";
import { JobMetadata } from "./Metadata/JobMetadata";

export const EDITOR_PROPERTIES_WIDTH = 500;

const LINE_COUNT = 64;

const getLineCount = (text?: string) => {
  return Math.ceil((text?.trim()?.length ?? 0) / LINE_COUNT);
};

const JobMetric = ({ value, label }) => {
  return (
    <div
      style={{
        display: "flex",
        gap: "0.25rem",
      }}
    >
      <Box fontSize="0.8rem">{value}</Box>
      <Box opacity={0.5} fontSize="0.8rem">
        {label}
      </Box>
    </div>
  );
};

const formatDuration = (durationInSeconds: number) => {
  const minutes = Math.floor(durationInSeconds / 60);
  const seconds = Math.floor(durationInSeconds % 60);
  return `${minutes}m ${seconds}s`;
};

export const EditorProperties = () => {
  const { transcriptJob, content, showJobProperties, setShowJobProperties } =
    useTranscriptJob();

  const right = showJobProperties ? "0" : `-150vw`;

  return (
    <div
      className="editor-properties"
      style={{
        height: "100vh",
        width: `${EDITOR_PROPERTIES_WIDTH}px`,
        backgroundColor: "rgb(245 245 245)",
        right,
        top: "0px",
        position: "absolute",
        transition: "right 0.2s ease",
        zIndex: "10000",
        boxShadow: "rgb(0 0 0 / 10%) -4px -1px 20px",
        borderLeft: "1px solid #ddd",
        padding: "0.8rem 1rem",
        paddingTop: "0",
        overflowY: "auto",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "1rem",
          position: "sticky",
          top: "0px",
          marginLeft: "-1rem",
          marginRight: "-1rem",
          backgroundColor: "rgb(245 245 245)",
          padding: "0.65rem 1rem",
          zIndex: "1000",
          borderBottom: "1px solid #c7c7c7",
          gap: "1.5rem"
        }}
      >
        <div style={{ fontSize: "1rem", fontWeight: "600", minWidth: "fit-content" }}>Job Details</div>
        <div
          style={{
            display: "flex",
            gap: "1rem",
            width: "100%",
          }}
        >
          <JobMetric
            value={getLineCount(makeTextContent(content))}
            label="lines"
          />
          <JobMetric
            value={formatDuration(
              transcriptJob?.audio?.audioLengthInSeconds ?? 0
            )}
            label="audio duration"
          />
        </div>
        <IconButton
          aria-label="close"
          onClick={() => setShowJobProperties(false)}
          sx={{ marginRight: "-0.5rem" }}
          variant="ghost"
        >
          <MdClose />
        </IconButton>
      </div>

      {transcriptJob?.comments && (
        <JobMetadata
          label="Comment about job"
          value={
            <Alert status="warning" mb="4" borderRadius="md">
              <AlertDescription>{transcriptJob?.comments}</AlertDescription>
            </Alert>
          }
        />
      )}

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "1.5rem",
          paddingBottom: "2rem",
        }}
      >
        <JobPriority />
        <JobStatus />
        <JobAssignee />
        <JobType />
        <JobCustomer />
        <JobCustomerWebsite />
        <JobContacts />
        <JobCustomerNotes />
        <JobCreatedOn />
      </div>
    </div>
  );
};

import { Skeleton, Stack } from "@chakra-ui/react";
import { randomInt } from "src/shared/generators";

export const EditorLoading = () => {
  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "1rem",
          borderBottom: "1px solid #ddd",
        }}
      >
        <Skeleton
          width="184px"
          height="32px"
        />
        <Skeleton
          width="184px"
          height="32px"
        />
      </div>
      <div
        className="ProseMirror"
        style={{
          marginTop: "2.5rem",
          gap: "2rem",
          display: "flex",
          flexDirection: "column",
          height: "calc(100vh - 200px)",
          minHeight: "calc(100vh - 180px)"
        }}
      >
        {Array.from({ length: 3 }).map((_, i) => (
          <Stack key={i} gap="2">
            {Array.from({ length: 5 }).map((_, i) => (
              <Skeleton
                key={i}
                width={`${randomInt(70, 100)}%`}
                height="6"
              />
            ))}
          </Stack>
        ))}
      </div>
      <Skeleton
        width="100%"
        height="73px"
        sx={{ position: "absolute", bottom: 0, left: 0 }}
      />
    </div>
  );
};

import { useModal } from "src/shared/context/ModalProvider";
import Modal from "../Modal";
import Input from "../Input";
import { useState } from "react";
import { useHistory } from "react-router-dom";

export const ModalJumpToJob = () => {
  const { closeModal } = useModal();
  const history = useHistory();
  const [jobId, setJobId] = useState<string | null>(null);

  const title = "Find Job";

  const jumpToJob = () => {
    closeModal();
    history.push(`/jobs/${jobId}`);
  };

  return (
    <Modal
      title={title}
      dialogue={{
        primary: {
          onClick: jumpToJob,
          title: "Go",
          autoFocus: true,
        },
        secondary: { onClick: closeModal },
      }}
    >
      <Input
        value={jobId}
        type="number"
        placeholder="Job ID"
        onKeyPress={(e) => {
          if (e.key === "Enter") {
            jumpToJob();
          }
        }}
        onChange={(e) => setJobId(e.target.value)}
      />
    </Modal>
  );
};

import * as Sentry from '@sentry/browser'
import env, { isNotRunningLocally } from './envConfig'
import { EventKind } from './events'
import { createLogEvent } from './api'

const log = (kind: EventKind, data?: object) => {
  if (isNotRunningLocally()) {
    Sentry.addBreadcrumb({
      type: kind.toString()
    })
    createLogEvent({
      event: kind,
      properties: data,
    })
  } else {
    console.info(kind, data) // eslint-disable-line
    createLogEvent({
      event: kind,
      properties: data,
    })
  }
}

const setupLogger = () => {
  if (isNotRunningLocally()) {
    Sentry.init({ dsn: env.SENTRY.DSN })
  }
}

const logUserSignedIn = (user) => {
  if (isNotRunningLocally()) {
    Sentry.configureScope(scope => {
      scope.setUser({ id: user?.id })
    })
  }
}

const logUserSignedOut = () => {
  log(EventKind.UserSignedOut)
}

const logTranscriptJobAbandoned = (job_id: number) => {
  log(EventKind.TranscriptJobAbandoned, { job_id: job_id })
}

export {
  log,
  logUserSignedIn,
  logUserSignedOut,
  logTranscriptJobAbandoned,
  setupLogger
}

import { Box, Flex, HStack, Text, } from "@chakra-ui/react";
import { TranscriptJob } from "src/shared/api";
import { useAuth } from "src/shared/context/AuthProvider";
import { ModalUpdateJobPriority } from "../ModalUpdateJobPriority";
import { useModal } from "src/shared/context/ModalProvider";
import { isAdmin } from "src/shared/utils";

const PriorityIcon = ({
  priority,
  size,
}: {
  priority: number;
  size?: string;
}) => {
  const boxSize = size ?? "1rem";
  const borderRadius = size ? "4px" : "2px";

  if (priority >= 4) {
    return (
      <Box
        bg="#ff6a00"
        boxSize={boxSize}
        borderRadius={borderRadius}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Text color="#fff">!</Text>
      </Box>
    );
  }
  return (
    <HStack
      boxSize={boxSize}
      gap="2px"
      borderRadius={borderRadius}
      overflow="hidden"
    >
      <Box
        bg={priority >= 0 ? "#000" : "#ccc"}
        height={boxSize}
        width=".5rem"
      />
      <Box
        bg={priority >= 2 ? "#000" : "#ccc"}
        height={boxSize}
        width=".5rem"
      />
      <Box
        bg={priority >= 3 ? "#000" : "#ccc"}
        height={boxSize}
        width=".5rem"
      />
    </HStack>
  );
};

export const priorityMap = {
  0: "Low",
  1: "Low",
  2: "Medium",
  3: "High",
  4: "Urgent",
};

export const PriorityBadge = ({
  transcriptJob,
  priority,
  size,
  readOnly,
  onSave,
}: {
  transcriptJob: TranscriptJob;
  priority: number;
  size?: string;
  readOnly?: boolean;
  onSave?: () => Promise<void> | void;
}) => {
  const { currentEmployee } = useAuth();
  const { openModal } = useModal()

  const admin = isAdmin(currentEmployee);
  
  const hoverProps = admin && !readOnly ? { _hover: { bg: "#ccc", cursor: "pointer" } } : {};

  const openUpdatePriorityModal = () => {
    if (!admin || readOnly) return;
    openModal(<ModalUpdateJobPriority transcriptJob={transcriptJob} onSave={onSave} />)
  } 

  return (
    <Flex
      display="inline-flex"
      alignItems="center"
      gap="1"
      onClick={openUpdatePriorityModal}
      transition="background-color 0.2s ease"
      borderRadius="md"
      padding="0.25rem"
      margin="-0.25rem"
      userSelect="none"
      {...hoverProps}
    >
      <PriorityIcon priority={priority} size={size} />
      <Text>{priorityMap[priority] ?? "Urgent"}</Text>
    </Flex>
  );
};

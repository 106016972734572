import { HStack, Stack, Textarea } from "@chakra-ui/react";
import { JobMetadata } from "./JobMetadata";
import { useTranscriptJob } from "src/shared/context/TranscriptJobProvider";
import { Button } from "../../Button";
import { useState } from "react";
import ResizeTextarea from "react-textarea-autosize";
import { isAdmin } from "src/shared/utils";
import { useAuth } from "src/shared/context/AuthProvider";
import { updateUserNotes } from "src/shared/api";
import { toast } from "sonner";

export const JobCustomerNotes = () => {
  const { currentEmployee } = useAuth();
  const { transcriptJob } = useTranscriptJob();

  const [nextInternalNotes, setNextInternalNotes] = useState(
    transcriptJob?.customerUser?.internalNotes || ""
  );

  const [isEditing, setIsEditing] = useState(false);
  const [loading, setLoading] = useState(false);

  const saveInternalNotes = async () => {
    try {
      if (!transcriptJob?.customerUserId) {
        throw new Error("No customer user found");
      }
      setLoading(true);
      await updateUserNotes(transcriptJob?.customerUserId, nextInternalNotes);
      toast.success("Customer notes saved successfully");
      setIsEditing(false);
    } catch (e) {
      console.error(e);
      toast.error("Failed to save customer notes");
    } finally {
      setLoading(false);
    }
  };

  return (
    <JobMetadata
      label="Customer notes"
      value={
        <Stack>
          <Textarea
            p="4"
            value={nextInternalNotes}
            onChange={(e) => {
              setNextInternalNotes(e.target.value);
            }}
            onKeyDown={(e) => {
              if (!isAdmin(currentEmployee)) return
              if (e.key === "Enter" && e.metaKey) {
                saveInternalNotes();
              }
            }}
            as={ResizeTextarea}
            resize="none"
            minRows={1}
            placeholder="No notes found"
            disabled={!isEditing}
            cursor="text !important"
          />

          {isAdmin(currentEmployee) && (
            <HStack>
              {!isEditing ? (
                <Button secondary size="sm" onClick={() => setIsEditing(true)}>
                  Edit notes
                </Button>
              ) : (
                <>
                  <Button
                    size="sm"
                    secondary
                    onClick={() => setIsEditing(false)}
                    disabled={loading}
                  >
                    Cancel
                  </Button>
                  <Button size="sm" onClick={saveInternalNotes} loading={loading} disabled={loading}>
                    Save
                  </Button>
                </>
              )}
            </HStack>
          )}
        </Stack>
      }
    />
  );
};
